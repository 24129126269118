.base {
  margin-right: 4rem;
  &:last-child {
    margin-right: 0;
  }
  h4 {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  ul {
    list-style: none;
  }
}

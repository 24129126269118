@import '../../../../styles/colors.scss';

.base {
  // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #DADBDC;
  height: 70vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    color: #424242;
    padding: 2rem 1rem 0;
    // background-image: $color-gradient-uhg-berry;
    // background-color: $color-navy;

    input {
      outline: 0;
      border: 1;
      transition: all 0.2s ease 0s;
      font-size: 1rem;
      padding: 0 1rem;
      height: 2.5rem;
      width: 100%;
      display: flex;
      position: relative;
      box-sizing: border-box;
      text-align: left;
      outline: 0;
      border-width: 0 0 2px;
  
    }

    h4{
      // width: 101px;
      height: 18px;      
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #424242;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

   
  }
 
}

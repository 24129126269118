@import '../../styles/colors.scss';

.btn {
  width: 120px;
  height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-left: 5px;
}

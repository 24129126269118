
@import '../../../../styles/colors.scss';

.base {
  display: grid;
  flex-direction: row;
  gap: 20px;  
  grid-template-areas:
  
    //'no-panel1'
    //'no-panel2'
    //'from-panel'
    'to-panel';
  
  // .fromPanel {
  //   grid-area: from-panel;    
  //   width: 215px;
  // }
  .toPanel {
    grid-area: to-panel;
    width: 215px;
  }
  // .noPanel1 {
  //   grid-area: no-panel1;
  // }
  // .noPanel2 {
  //   grid-area: no-panel2;
  // }
}
.todateFormat{
    width: 90px;
    height: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #323334;
    padding:.5rem;
    text-align: end;
}
.fromdateFormat{
  width: 65px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #323334;
  padding:.5rem;
  text-align: end;
}
.dateDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 130px;
  //border: 1px solid #929496;
  border-radius: 4px;
  padding-left: 8px;
}
@media (min-width: 1000px) {
  .base {
    //grid-template-columns: 4fr 2fr 2fr 2fr;
    grid-template-areas: 'to-panel';
    font-size: 12px;
    padding: 1rem 0rem 1rem;
    padding-left: 45rem;
    // .fromPanel,
    // .noPanel1,
    // .noPanel2,
    .toPanel {
      span {
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        color: #424242;
      }
      .dropDowns {
        width: 230px;
        height: 30px;
        font-size: 12px;
      }
      .select {
        width: 230px;
        height: 30px;
        font-size: 12px;
      }
      .calenderDiv {
        border: 1px solid;
        //width: 200px;
        height: 32px;
        border-radius: 5px;
        border-color: hsl(0, 0%, 80%);
        padding-left: 5px;
        padding-top: 5px;
        .calenderLabel {
          vertical-align: center;
          font-size: 12px;
          //padding-left: 15px;
          margin-left: 8px;
        }
      }
    }
  }
}

@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;

  grid-template-areas:
    'medical-panel'
    'pharmacy-panel'
    'classification-panel';
  .medicalPanel {
    grid-area: medical-panel;
  }
  .pharmacyPanel {
    grid-area: pharmacy-panel;
  }
  .classificationPanel {
    grid-area: classification-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 6fr 6fr;
    grid-template-areas: 'medical-panel pharmacy-panel';
    font-size: 12px;
    margin-top: 15px;
    box-shadow: 0 0 4px 0 #888888;
    .medicalPanel,
    .pharmacyPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      border-right: 2px solid rgba(185, 185, 185, 0.5);
      Span {
        font-size: 12px;
        padding-left: 10px;
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    .classificationPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      Span {
        font-size: 12px;
        padding-left: 10px;
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

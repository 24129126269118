@import '../../../styles/colors.scss';

.base {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  // height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: $color-backgroud-white;

  .header {
    color: #424242; //$color-white;
    padding: 1.5rem 1rem;
    //background-image: $color-gradient-uhg-berry;
    //background-color: $color-navy;
    position: relative;
    h3 {
      button {
        font-size: 16px;
        margin-right: 90px;
        margin-top: 10px;
        display: contents;
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
    button {
      background: none;
      border: 0;
      cursor: pointer;
      padding: 0.25rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: #424242; //white;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    h3 {
      padding-right: 1.5rem;
    }

    .position {
      padding: 0;
      margin: 0;
      // opacity: 0.6;
      // text-transform: uppercase;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .body {
    height: 100%;
    padding: 1rem;

    .viewMore {
      color: black;
      text-decoration: underline;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }

  .checkboxText {
    color: #424242;
    font-family: 'graphik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
  .checkboxPosition {
    margin-top: -18px;
  }
}

@import '../../styles/colors.scss';

.base {
  box-sizing: border-box;
  height: 35px;
  position: relative;
  flex-shrink: 0;
  background-color: $color-gray-5;
  font-size: 1rem;

  .container {
    // height: 100%;

    > div {
      align-items: flex-end;
      box-sizing: border-box;
      display: flex;
      height: 100%;
      padding: 0.5rem 1rem;
    }

    .logo {
      display: inline-flex;

      svg {
        height: 1em;
        position: relative;
        top: -3px;
      }

      &.pointer {
        cursor: pointer;
      }
    }
  }

  .items {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .bellPosition{
    overflow: initial;
    width: 17px;
    height: 21px;
    left: 100px;
    top: 10px;
    margin-right: 10px;
    
  }
}

.uhc {
  &::after {
    background: $color-gradient-uhc;
  }

  
}

// @import '../../styles/colors.scss';

// .base {
//   margin-top: auto;
//   background-color: $color-gray-5;
// }

// .main {
//   margin: 2rem 0;
//   display: flex;
//   margin-left: 6rem;
// }

// .copyright {
//   display: flex;
//   font-weight: 400;
//   padding-top: 2rem;
//   // border-top: 0.0625rem solid $color-gray-4;
//   font-size: 0.875rem;
//   > ul {
//     list-style: none;
//     display: flex;
//     margin: 0;
//     padding: 0;
//     li {
//       padding-right: 2rem;
//       a {
//         text-transform: uppercase;
//         color: $color-gray-2;
//         text-decoration: none;
//       }
//     }
//   }
//   > div {
//     display: flex;
//     color: $color-gray-2;
//     flex-grow: 1;
//     justify-content: flex-end;
//   }
// }

@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  // height: 130px;
  grid-template-areas:
    'title-panel'
    'count-panel'
    'filter-panel';
  .titlePanel {
    grid-area: title-panel;
  }
  .countPanel {
    grid-area: count-panel;
  }
  .filterPanel {
    grid-area: filter-panel;
  }

  .ddl {
    width: 100%;
    height: 35px;
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-top: 4px;
    width: 167px !important;
    height: 45px !important;
    left: 1179px !important;
    top: 133px !important;
    background: #ffffff !important;
    // box-shadow: 0px 0px 4px rgb(0 0 0 / 50%) !important;
    white-space: nowrap !important;
    font-size: 16px;
    font-family: 'graphik';
    border-radius: 4px;
    margin-bottom: 23px;
  }
  .titleVul {
    height: 42px;
    left: 0%;
    right: 12.79%;
    top: calc(50% - 42px / 2 - 17px);
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #424242;
  }
  .titleVulInner {
    height: 51px;
    left: 0%;
    right: 0%;
    top: calc(50% - 38px / 2 + 19px);
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #424242;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 3fr 8.9fr 1fr;
    grid-template-areas: 'title-panel count-panel filter-panel';
    font-size: 12px;
    padding-bottom: 10px;
    .countPanel {
      display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      background-color: $color-cloud;
      // height: 80px;
      background: #f5f5f5;
      .dateWeek {
        padding-left: 1rem;
        .position {
          font-size: 14px;
          padding: 0;
          margin: 0;
        }
        .headerSpan {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .titlePanel {
      // padding: 0.5rem 0rem;
      h4 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}

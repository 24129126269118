@import '../../styles/colors.scss';

.base {
  &.open {
    > span {
      > button {
        > i {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &.selected {
    > span {
      > button,
      > button:hover {
        background: $color-blue-2;
        color: $color-white;
      }
    }
  }

  > span {
    display: block;
    position: relative;

    %common-styles {
      box-sizing: border-box;
      color: #424242;
      cursor: pointer;
      display: block;
      padding: 0.75rem 1rem;
      text-align: initial;
      width: 100%;

      &:hover {
        color: $color-primary-pink;
      }
    }

    > a {
      @extend %common-styles;
      text-decoration: none;
    }

    > button {
      @extend %common-styles;
      background: none;
      border: 0;
      font-family: inherit;
      font-size: inherit;

      > i {
        display: inline-flex;
        margin-left: 0.5rem;
        transition: transform 0.1s ease-in-out;
      }
    }
  }
}

.base {
  padding: 2rem;
  position: relative;
  border: 0.0625rem solid #e0e0e0;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  //margin-bottom: 2rem;
  margin: 1rem 1rem 1rem 1rem;
  color: #555555;

  &.success {
    background: #c7dba4;
    color: #3c4e1f;
  }

  &.error {
    background: #ffc7c4;
    color: #eb0c00;
  }

  &.warning {
    background: #fae7b7;
    color: #826009;
  }

  button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0.25rem;
    position: absolute;
    right: 1rem;
    top: 1rem;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

@import '../../../styles/colors.scss';

.base {
  padding: 1rem 1rem;
  //border-bottom: 1px solid #efefef;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #424242;
  margin-left: -20px;

  button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0.25rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    left: 1rem;
    color: #424242; //$color-blue-2;
    text-decoration: none; // underline;
    width: 60%;
    font-weight: 500;

    &:hover {
      color: $color-blue-1;
    }
  }
}

@import "../../styles/colors.scss";

.base {
  position: relative;

  > button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: $color-white
      url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlnb24gZmlsbD0iIzQ0NDQ0NCIgcG9pbnRzPSIzIDUgMTMgNSA4IDExIiAvPjwvc3ZnPg==)
      no-repeat right 0.5rem center;
    border: 0.0625rem solid $color-gray-4;
    box-sizing: border-box;
    border-radius: 0.25rem;
    display: block;
    font-family: inherit;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1rem;
    outline: none;
    overflow: hidden;
    padding: 0.5rem;
    padding-right: 2rem;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    &:hover {
      border-color: $color-blue-2;
    }

    &:focus {
      border-color: $color-blue-2;
      box-shadow: 0 0 0 0.1875rem $color-blue-3;
    }

    &.error {
      border-color: $color-error-red;
      box-shadow: 0 0 0 0.1875rem lighten($color-error-red, 40%);
    }
  }

  .popover {
    background: $color-white;
    border: 0.0625rem solid $color-gray-4;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    max-height: 320px;
    opacity: 0;
    outline: none;
    overflow: hidden;
    position: absolute;
    transform: translateY(-1rem);
    transition: all 0.1s ease-in-out;
    visibility: hidden;
    width: 320px;
    z-index: 99;

    ::-webkit-scrollbar {
      background: $color-gray-7;
      width: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      background: $color-gray-5;
      height: 3.5rem;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: $color-gray-4;
    }

    ::-webkit-scrollbar-thumb:active {
      background: $color-gray-3;
    }

    &:focus {
      border-color: $color-blue-2;
      box-shadow: 0 0 0 0.1875rem $color-blue-3;
    }

    &.open {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  .search {
    border-bottom: 0.0625rem solid $color-gray-4;
    padding: 0.75rem;

    > div {
      position: relative;
    }

    .searchBox {
      width: 100%;
    }

    button {
      background: none;
      border: 0;
      border-radius: 0.5rem;
      fill: $color-gray-5;
      font-size: 0;
      margin: -0.5rem;
      outline: none;
      position: absolute;
      right: 1rem;
      top: 50%;

      &:focus {
        box-shadow: 0 0 0 0.1875rem $color-blue-3;
        fill: $color-blue-2;
      }

      &:hover {
        fill: $color-blue-2;
      }

      &:active {
        fill: $color-blue-1;
      }
    }
  }

  .items {
    overflow: auto;

    button {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: none;
      border: 0;
      box-sizing: border-box;
      cursor: pointer;
      font-size: initial;
      font-weight: initial;
      outline: none;
      overflow: hidden;
      padding: 0.75rem 1rem;
      padding-left: 3rem;
      position: relative;
      text-align: initial;
      text-overflow: ellipsis;
      transition: all 0.3s ease-in-out;
      white-space: nowrap;
      width: 100%;

      &:focus {
        box-shadow: inset 0 0 0 0.1875rem $color-blue-3;
      }

      &:hover {
        background: $color-blue-2;
        color: #fff;

        svg {
          fill: $color-white;
        }
      }

      &:active {
        background: $color-blue-1;

        svg {
          fill: $color-white;
        }
      }
    }

    svg {
      fill: $color-gray-4;
      left: 0.75rem;
      margin-top: -0.75rem;
      position: absolute;
      top: 50%;

      &.indeterminate,
      &.selected {
        fill: $color-blue-2;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul li span {
      border-bottom: 0.0625rem solid $color-gray-4;
      cursor: default;
      display: flex;
      font-weight: bold;
      padding: 0.75rem 1rem;
    }

    .noMatch {
      padding: 0.75rem 1rem;
    }
  }

  .errorText {
    color: $color-error-red;
    display: block;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }

  &.multiTier {
    &.multiSelect {
      .items {
        ul button {
          border-bottom: 0.0625rem solid $color-gray-4;
        }

        ul ul svg {
          left: 2.75rem;
        }

        ul ul button {
          border-bottom: 0;
          padding-left: 5rem;
        }
      }
    }

    .items {
      ul button {
        font-weight: bold;
      }

      ul ul button {
        font-weight: initial;
      }
    }
  }
}

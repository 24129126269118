@import '../../styles/colors.scss';

.base {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  background-color: $color-cloud;

  .playPause {
    order: 1;
  }

  .icons {
    padding: 0.25rem 1rem 1.25rem;
    background-color: $color-cloud;
    height: 35px;

    .iconText {
      color: #666;
      font-size: 0.75rem;
      position: relative;
      top: -9px;
    }

    .rewind {
      transform: translateX(-50%);
    }

    .play {
      left: 5px;
      transform: translateX(-30%);
    }

    .forward {
      transform: translateX(-50%);
    }

    button {
      text-decoration: none;
      color: $color-blue-2;

      &:hover {
        color: $color-blue-1;
      }

      &:disabled {
        color: $color-gray-2;
      }
    }
  }

  .slider {
    // margin-left: 1rem;
    order: 3;
    padding: 0.75rem 2.5rem;
    flex-grow: 2;
    background-color: white; // $color-cloud;  //Added by Avanish
    height: 35px;
    white-space: nowrap;
  }

  .options {
    padding: 1rem 2rem 1rem 0;
    order: 2;
    background-color: $color-cloud;

    ul {
      li {
        button {
          background-color: $color-cloud;
        }
      }
    }
  }

  .loading {
    padding: 1rem;
    height: 35px;
    top: 10px;
    vertical-align: center;
  }
  .loadingSpinner {
    padding: 0.75rem 1rem;
  }
}

@media (min-width: 1000px) {
  .base {
    display: flex;
    flex-wrap: nowrap;
    background-color: white;
    padding-top: 10px;
    .playPause {
      order: 1;
    }

    .slider {
      order: 2;
      width: 100%;
      padding: 0.75rem 2.5rem;
    }

    .options {
      order: 3;
    }
  }
}

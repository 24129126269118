@import "../../styles/colors.scss";

.base {
  > ul {
    list-style: none;
    margin: 0;
    padding: 0.75rem;

    > li {
      animation: pulsate 2s linear;
      animation-iteration-count: infinite;
      background: $color-gray-5;
      margin-bottom: 0.75rem;
      min-height: 2rem;
      opacity: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @keyframes pulsate {
    0% {
      opacity: 0.5;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    75% {
      opacity: 0.65;
    }
    100% {
      opacity: 0.5;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  background: $color-white;
  border: 0.0625rem solid $color-gray-6;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  // border-radius: 0.25rem;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;

  h4 {
    margin-bottom: 1rem;
  }

  &.primary {
    // &::before {
    //   background: $color-gradient-optum; // Default
    //   // border-top-left-radius: 4px;
    //   // border-top-right-radius: 4px;
    //   content: '';
    //   height: 4px;
    //   left: 0;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    // }
  }
}

.uhc {
  // &.primary {
  //   &::before {
  //     background: $color-gradient-uhc;
  //   }
  // }
}

@import '../../styles/colors.scss';

.icon {
  // color: $color-blue-2;
  // cursor: pointer;
  // font-size: 20px;
  // vertical-align: middle;
  color: white;
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
  border-color: darkblue;
  background: #002677;
  border-radius: 11px;

  &:hover {
    // color: $color-blue-1;
    color: white;
  }
}

@import '../../../styles/colors.scss';

.base {
  //box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
  //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.192417);

  .header {
    color: #424242; //$color-navy;
    //padding: 2rem 1rem 0;
    // background-image: $color-gradient-uhg-berry;
    // background-color: $color-navy;
    //background-image: $color-gradient-uhg-berry;
    //background-color:#e6e6f375;
    //font-family: 'graphik';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;

    input {
      outline: 0;
      border: 1;
      transition: all 0.2s ease 0s;
      font-size: 1rem;
      padding: 0 1rem;
      height: 2.5rem;
      width: 100%;
      display: flex;
      position: relative;
      box-sizing: border-box;
      text-align: left;
    }
  }
}
.btn {
  color: red;
  font-size: '20px';
}

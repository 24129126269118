@import '../../../styles/colors.scss';

.base {
  width: 95%;
  display: grid;
  gap: 30px;
  grid-template-areas:
    'covid-panel'
    'hrr-panel'
    'flu-panel';
  .covidPanel {
    grid-area: covid-panel;
  }
  .hrrPanel {
    position: relative;
    grid-area: hrr-panel;
  }
  .fluPanel {
    position: relative;
    grid-area: flu-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    width: 95%;
    grid-template-columns: 4fr 4fr 4fr;
    grid-template-areas: 'covid-panel hrr-panel flu-panel';
    padding: 1rem 2rem 1rem;
    .covidPanel,
    .hrrPanel {
      position: relative;
      border-radius: 4px;
      // margin-left: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      background-color: $color-cloud;
      .alignRight {
        display: flex;
        justify-content: flex-end;
      }
      .alignLeft {
        display: flex;
        justify-content: flex-start;
      }
      .dashboardCard {
        flex-grow: 4;
        max-width: 100%;
        .dashboardCardHeader {
          font-size: 16px;
          padding: 0.5rem 0.5rem;
          color: #000;
          .btn {
            color: #316bbe;
            font-weight: 400;
            text-decoration: underline;
          }
          .mapName {
            color: $color-primary-blue;
          }
        }
      }
      .descriptionDiv {
        padding: 0.5rem 0.5rem;
        font-size: 14px;
      }
      .sourceDiv {
        padding: 0.5rem 0.5rem;
        .graphSource {
          font-size: 14px;
          Span {
            font-weight: 600;
          }
        }
      }
    }
    .fluPanel {
      border-radius: 4px;
      // margin-left: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      background-color: $color-cloud;
      .alignRight {
        display: flex;
        justify-content: flex-end;
      }
      .alignLeft {
        display: flex;
        justify-content: flex-start;
      }
      .dashboardCard {
        flex-grow: 4;
        max-width: 100%;
        .dashboardCardHeader {
          font-size: 16px;
          padding: 0.5rem 0.5rem;
          color: #000;
          .btn {
            color: #316bbe;
            font-weight: 400;
            text-decoration: underline;
          }
          .mapName {
            color: $color-primary-blue;
          }
        }
      }
      .descriptionDiv {
        padding: 0.5rem 0.5rem;
        font-size: 14px;
      }
      .sourceDiv {
        padding: 0.5rem 0.5rem;
        .graphSource {
          font-size: 14px;
          Span {
            font-weight: 600;
          }
        }
      }
    }
  }
}

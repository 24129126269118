@import '../../../../styles/colors.scss';

.base {
  .header {
    display: block;
    // background: $color-cloud;
    .content {
      padding: 0 1rem 0rem;
      p {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
    ul,
    ol {
      margin-left: 0rem;
      padding: 0 1rem 0rem;
      margin-bottom: 10px;
    }
  }

  .body {
    padding: 1rem 1rem 1rem;
    min-height: 70vh;
  }
}

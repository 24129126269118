@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;

  grid-template-areas:
    'gender-panel'
    'vaccine-panel';
  .genderPanel {
    grid-area: gender-panel;
  }
  .vaccinePanel {
    grid-area: vaccine-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 5fr 7fr;
    grid-template-areas: 'gender-panel vaccine-panel';
    font-size: 12px;
    margin-top: 15px;
    .genderPanel,
    .vaccinePanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      box-shadow: 0 0 4px 0 #888888;
      Span {
        font-size: 18px;
       padding-left: 5px;
        Span {
          font-size: 12px;
        padding-left: 5px;
        }
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

@import '../../styles/colors.scss';
.base {
  .header {
    display: block;
    // background: $color-cloud;

    .content {
      padding: 19px 2.5rem 4rem;
    }
  }

  .body {
    padding: 2rem 2.5rem 4rem;
    min-height: 70vh;
  }
}

.bottomPadded {
  padding-bottom: 2rem;
}

@import '../../../../styles/colors.scss';

.containerDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .fluContainer {
    width: 48%;
    float: left;
  }
  .covidContainer {
    margin-left: 4%;
    width: 48%;
    float: right;
  }
  .bottomPadded {
    padding-bottom: 2rem;
    ul {
      list-style-type: none;
    }
  }
  .bottomPadded2 {
    padding-bottom: 2rem;
    ul > li {
      margin-bottom: 5px;
    }
  }
}

@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 24px;
  grid-template-areas:
    'date-panel'
    'filter-panel'
    'map'
    'cbsas-panel'
    'accuracy-panel'
    'timeline-panel'
    'genderage-panel';

  .map {
    grid-area: map;

    > svg {
      position: absolute;
      background: #fff;
      z-index: 1;
      padding: 16px;
      margin-top: 16px;
      margin-left: 16px;
    }

    .filter {
      width: 100%;
      height: 20px;
      font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4b4d4f;
      margin-top: 16px;
    }
    .Popup {
      box-sizing: border-box;
      display: flex;
      gap: 16px;
      width: 391px;
      height: 150px;
      background: #ffffff;
      border: 1px solid #cbcccd;
      border-radius: 4px;
      z-index: 2;
      margin-left: 1%;
      margin-top: 15px;
      box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px 16px 16px;
      position: absolute;

      .Popupheader {
        color: #424242;
        .PopupheaderText {
          width: 343px;
          height: 20px;
          font-family: 'Optum Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #002677;
        }
        .PopupheaderText1 {
          width: 99%;
          height: 40px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          // text-decoration-line: underline;
          color: #4b4d4f;
          cursor: pointer;
          margin-top: 16px;
        }
      }
    }
  }
  .cbsasPanel {
    grid-area: cbsas-panel;
  }

  .datePanel {
    grid-area: date-panel;
  }

  .timelinePanel {
    grid-area: timeline-panel;
  }
  .genderagepanel {
    grid-area: genderage-panel;
  }
  .accuracyPanel {
    grid-area: accuracy-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 1fr 3fr;
    // margin-top: 2rem;
    grid-template-areas:
      'date-panel filterpanel'
      'cbsas-panel map'
      'timeline-panel timeline-panel'
      'genderage-panel genderage-panel';

    .map {
      min-height: 40vh;
      border-top: 1.5px solid #cbcccd;
      border-right: 1.5px solid #cbcccd;
      border-bottom: 1.5px solid #cbcccd;
      border-left: 1.5px solid #cbcccd;
      border-radius: 0px 4px 0px 0px;
      margin-left: -25px;
    }
    .datePanel {
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22.78px;
        line-height: 30px;
        color: #424242;
      }
      span {
        width: 365px;
        height: 16px;
        left: 0px;
        font-style: normal;
        font-size: 14px;
        line-height: 2px;
        color: #424242;
      }
    }
    .timelinePanel {
      grid-area: timeline-panel;
      background-color: #fff;
      border-top: 1.5px solid #cbcccd;
    }
    
    .mapPopup {
      width: 54vh;
      margin-left: 30%;
      z-index: 1;
      position: absolute;
      margin-top: 5px;
      background-color: white;
      box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
    }
    .providerHeaderCollection {
      border-bottom: 1.5px solid #cbcccd;
      height: 50px;
    }
    .providerHeader {
      border-bottom: 1.5px solid #cbcccd;
      height: 90px;
    }
    .notAvailable {
      padding-left: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #4b4d4f;
      font-family: 'Optum Sans', sans-serif;
    }
  }
}

@import '../../styles/colors.scss';
.base {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  width: 93%;
  margin-left: 2%;
  // height: 86px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

  .kpisDiv {
    width: 100%;
    display: flex;
    font-size: 12px;
    padding: 10px 10px 0px;
    .heading {
      width: 18%;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      // text-decoration-line: underline;
      color: #424242;
      justify-content: flex-start;
    }
    .title {
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #002677;
    }
    .infoDiv1,
    .infoDiv2,
    .infoDiv3 {
      margin-left: 2%;
      width: 25%;
      justify-content: flex-end;
      span {
        padding-left: 10px;
        font-weight: 700;
      }
    }
    // .infoDiv2 {
    //   margin-left: 2%;
    //   width: 25%;
    //   justify-content: flex-end;
    // }
    // .infoDiv3 {
    //   margin-left: 2%;
    //   width: 25%;
    //   justify-content: flex-end;
    // }
  }
}

@import '../../styles/colors.scss';

.base {
  // display: block;
  // margin-bottom: 0.25rem;
  // text-transform: uppercase;
  // color: #424242;
  // font-family: Graphik-semibold, Arial, Helvetica, sans-serif;
  // font-size: 14px;
  // font-weight: 600;
  // font-stretch: normal;
  // font-style: normal;
  // line-height: 1.57;
  // letter-spacing: 3px;
  display: block;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  color: #424242;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.57;

  &.error {
    color: $color-error-red;

    > i {
      margin-right: 0.5rem;
    }
  }

  .required {
    color: $color-error-red;
    font-size: 1.25rem;
  }
}

@import '../../styles/colors.scss';

.base {
  .header {
    display: block;
    background: $color-cloud;

    .content {
      padding: 0 2.5rem 4rem;
    }
  }

  .body {
    padding: 2rem 2.5rem 4rem;
    min-height: 70vh;
  }

  .cell {
    color: $color-blue-2;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .margin {
    margin-right: 0.5rem;
  }

  .detailsFlex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 0.125rem solid $color-gray-6;
  }

  .details {
    > div {
      margin-bottom: 1rem;
    }

    margin-bottom: 5rem;
  }
}

@import '../../styles/colors.scss';

.base {
  width: 100%;
  .select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    padding: 1rem 1rem;
    background: $color-cloud;
  }
}

@media (min-width: 1000px) {
  .base {
    width: 100%;
    .select {
      padding: 2rem 1rem;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  width: 100%;
  .select {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    padding: 1rem 1rem;
    background: $color-cloud;
  }

  // .body {
  //   padding-top: 4rem;
  //   min-height: 10vh;
  // }
}

@media (min-width: 1000px) {
  .base {
    width: 100%;
    // .select {
    //   padding: 2rem 1rem;
    // }

    // .body {
    //   padding-top: 4rem;
    //   min-height: 50vh;
    // }

    svg{
    font-family: 'graphik'!important
    }
  }
  
}

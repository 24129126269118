@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  
}
.maindivFormat { 
  padding-top: 16px; 
  width: 100%;
  display: flex;
}
.formheader
{
  font-family: 'Optum Sans', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 29px;
line-height: 36px;
color: #002677;

}

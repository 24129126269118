@import '../../../../styles/colors.scss';

.base {
  // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  height: 70vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    color: $color-gray-1;
    padding: 10px 10px;
    // background-image: $color-gradient-uhg-berry;
    // background-color: $color-navy;
    position: relative;

    button {
      background: none;
      border: 0;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 10px;
      color: black;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    h3 {
      padding-right: 1.5rem;
    }

    .position {
      padding: 0;
      margin: 0;
      opacity: 0.6;
      text-transform: uppercase;
    }
  }

  .body {
    overflow-x: hidden;
    height: 100%;
    padding: 1rem;

    .viewMore {
      color: black;
      text-decoration: underline;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }
  .content {
    width: 100%;
    padding-left: 10px;
    padding-top: 2px;
    font-size: 12px;
    padding-right: 10px;

    > table {
      border-collapse: collapse;
      width: 96%;
      text-align: left;
      > tbody > tr {
        border-bottom: 1.5px solid #000;
      }
      > tbody > tr > td {
        height: 30px;
        vertical-align: middle;
      }
      .formulaSpanShow {
        display: revert;
        height: auto;
        background-color: '#f2f2f';
        padding: 10px 10px;
      }
      .formulaSpanHide {
        display: none;
        height: auto;
        background-color: '#f2f2f';
        padding: 10px 10px;
      }
    }
  }
}

@import '../../styles/colors.scss';

.base {
  width: 100%;
  // height: 570px;
  // display: grid;
  // gap: 20px;
  // grid-template-areas:
  //   'date-panel'
  //   'timeline-panel'
  //   'map'
  //   'cbsas-panel'
  //   'accuracy-panel';

  // .map {
  //   grid-area: map;

  //   > svg {
  //     position: absolute;
  //     background: #fff;
  //     z-index: 10;
  //     padding: 10px;
  //   }
  // }

  // .cbsasPanel {
  //   grid-area: cbsas-panel;
  // }

  // .datePanel {
  //   grid-area: date-panel;
  // }

  // .timelinePanel {
  //   grid-area: timeline-panel;
  // }
  // .accuracyPanel {
  //   grid-area: accuracy-panel;
  // }
}

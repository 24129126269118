@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;

  grid-template-areas:
    'vaccine-panel'
    'rate-panel';
  .ratePanel {
    grid-area: rate-panel;
  }
  .vaccinePanel {
    grid-area: vaccine-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 6fr 6fr;
    grid-template-areas: 'vaccine-panel rate-panel';
    font-size: 12px;
    margin-top: 15px;
    .ratePanel,
    .vaccinePanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      height: 260px;
      box-shadow: 0 0 4px 0 #888888;
      Span {
        font-size: 18px;
        padding-left: 10px;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

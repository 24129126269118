@import '../../styles/colors.scss';
.base {
  //border: 1.5px solid #cbcccd;
  border-top: 1.5px solid #cbcccd;
  //border-right: 1.5px solid #CBCCCD;
  border-bottom: 1.5px solid #cbcccd;
  border-left: 1.5px solid #cbcccd;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // border-radius: 3px;
  border-radius: 4px 0px 0px 4px;
  .header {
    color: #002677;
    padding: 1.5rem 1rem 0;
    input {
      outline: 0;
      border: 1;
      transition: all 0.2s ease 0s;
      font-size: 1rem;
      padding: 0 1rem;
      height: 2.5rem;
      width: 100%;
      display: flex;
      position: relative;
      box-sizing: border-box;
      text-align: left;
      border-width: 0 0 2px;
    }

    h4 {
      width: 100%;
      height: 18px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #4b4d4f;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
  .scroll {
    overflow-y: scroll;
    padding: 0.5rem 0.5rem 0;
    margin-left: 6px;
    margin-right: 4px;
  }

  .scroll::-webkit-scrollbar {
    width: 10px;
  }

  .scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 15px #b1b2b4;
  }

  .sideTableHeader {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #4b4d4f;
    margin-bottom: 16px;
  }

  .columnRate {
    width: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #4b4d4f;
    text-align: center;
  }
  .columnDays {
    width: 42%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #4b4d4f;
    //display: flex;
    span:nth-of-type(1) {
      margin-right: 2px;
    }
  }
  .percentageRed {
    color: #c40000;
  }
  .percentageGreen {
    color: #007000;
  }
}

.Layout {
  padding: 1rem 0rem;
  border-bottom: 1px solid #cbcccd;
  position: relative;
  overflow: hidden;
  button {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #0c55b8;
    &:hover {
      color: #003a8d;
      font-weight: bold;
    }
    &:active {
      color: #002677;
      font-weight: bold;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  font-size: .9rem;

  span {
    border-left: 0.0625rem solid $color-gray-5;
    margin-left: 1rem;
    padding-left: 1rem;
  }
}

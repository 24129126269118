@import '../../../../styles/colors.scss';

.base {
  // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
  // height: 78vh;
  // display: flex;
  // flex-direction: column;
  // overflow: hidden;
  // border: 1px solid rgb(156, 156, 156);
  // border-top: 2px solid rgb(156, 156, 156);

  .header {
    color: #424242;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;
    // padding: 0.5rem 1rem 1rem;
    // background-color: $color-white;
    // border-bottom: 1px solid rgb(156, 156, 156);

    input {
      outline: 0;
      border: 1;
      transition: all 0.2s ease 0s;
      font-size: 1rem;
      padding: 0 1rem;
      height: 2rem;
      width: 100%;
      display: flex;
      position: relative;
      box-sizing: border-box;
    }
  }
  // .subheading {
  //   color: $color-gray-1;
  //   font-weight: lighter;
  //   font-size: 14px;
  // }
  // .heading {
  //   color: $color-gray-1;
  //   font-weight: normal;
  //   font-size: 20px;
  // }
  // .title {
  //   color: $color-gray-1;
  //   font-weight: bold;
  //   font-size: 16px;
  // }
}

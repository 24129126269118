@import '../../../styles/colors.scss';

.base {
  width: 95%;
}

@media (min-width: 1000px) {
  .base {
    padding: 1rem 1.5rem 0rem;
    .mainPanel {
      margin-left: 5px;

      padding: 0.5rem 0.5rem;
      // background-color: $color-cloud;
    }
  }
}

@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 10px;
  grid-template-areas:
    'date-panel'
    'datePanel1'
    'timeline-panel'
    'legend'
    'map'
    'mapfilter'
    'cbsas-panel';

  .map {
    grid-area: map;
    background: #f7f7f7;

    > svg {
      position: absolute;
      background: #f7f7f7;
      //z-index: 10;
      padding: 10px;
      margin-top: 40px;
    }
  }

  .cbsasPanel {
    grid-area: cbsas-panel;
  }

  .datePanel {
    grid-area: date-panel;
  }
  .datePanel1 {
    grid-area: covidheader;
  }
  .mapPopup {
    height: auto;
    width: 500px;
    margin-left: 50%;
    z-index: 2;
    position: absolute;
    margin-top: 2%;
    background-color: #f4f4f4;
  }
  // .timelinePanel {
  //   grid-area: timeline-panel;
  //   border: 5px solid rgba(185, 185, 185, 0.418706);  //Added by Avanish for border
  // }
  .mapfilter {
    grid-area: mapfilter;
  }
}

.timelinePanel {
  grid-area: timeline-panel;
  border: 1px solid rgba(185, 185, 185, 0.418706); //Added by Avanish for border
  height: 120px;
  background-color: #fff;
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      'covidheader  mapfilter'
      'date-panel  map'
      'cbsas-panel timeline-panel';

    .map {
      min-height: 40vh;
    }
  }
}

@import '../../styles/colors.scss';



.base {
  .header {
    display: block;
    background: $color-cloud;

    .content {
      padding: 0 2.5rem 8rem;
    }
  }
  .body {
    min-height: 70vh;
  }
  .pageHeader {
    width: 100%;
    display: flex;
  }
  .pageHeaderText {
    width: 50%;
    color: #002677;
    font-weight: 700;
    font-size: 22.78px;
    font-style: normal;
    h5{    font-family: "Optum Sans";
      font-size: 22px;
      font-weight: 600;
      color: rgb(0, 38, 119)}
  }
  .pageHeaderTextValue {
    width: 50%;
    // display: flex;
    text-align: right;
    font-weight: 700;
    font-size: 12px;
    .natDiv {
      width: 35%;
      text-align: center;
      // margin-right: 2%;
    }
    .stateDiv {
      width: 35%;
      border-left: 1px solid #cbcccd;
      text-align: center;
      // margin-right: 2%;
    }
    .geoDiv {
      width: 55%;
      border-left: 1px solid #cbcccd;
      // margin-right: 2%;
      text-align: center;
    }

    .firstSpan {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }

    .secondSpan {
      padding: 0 8px 0 8px;
    }
    .forthSpan {
      line-height: 18px;
      font-size: 14px;
      padding-left: 4px;
      > span {
        font-family: 'Optum Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding-left: 4px;
        /* identical to box height, or 129% */

        /* neutral/80 */

        color: #4b4d4f;
      }
    }
    .stdatavalueup {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #c40000;
    }

    .datavaluedown {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #007000;
    }
  }
  .pageHeaderSearch {
    // text-align: right;
    // margin-top: 2rem;
    // margin-left: 60%;
    // width: 40%;

    text-align: right;
    margin-top: 2rem;
    margin-left: 50%;
    width: 50%;
  }
}

@import '../../styles/colors.scss';

.base {
  display: flex;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:horizontal {
    height: 0.5rem;
  }
  ::-webkit-scrollbar:vertical {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: $color-gray-2;
  }
  > div {
    &.table {
      width: 100%;
      flex-grow: 1;
      justify-content: flex-end;

      > div {
        &.toggleButton {
          padding-bottom: 0.5rem;
          font-size: 1.5rem;
        }
      }

      > div {
        &.wrapper {
          max-width: 100%;
          position: relative;
          overflow: auto;
          //border: 0.0625rem solid #979797;
          white-space: nowrap;
          &.wrapperXl {
            max-width: 1400px;
          }
        }
      }
    }
  }

  table {
    border-spacing: 0;
    width: 100%;

    thead {
      td,
      th {
        border-bottom: 0.0625rem solid #979797;
        padding: 1rem;
        text-align: left;
        vertical-align: top;
        user-select: none;
        white-space: nowrap;
        > div {
          margin-top: 1rem;
        }
      }
      // tr {
      //   background-color: #d8d8d8;
      // }
      th {
        top: 0;
        position: sticky;
        z-index: auto;
        //background-color: inherit;
        &:first-child {
          z-index: auto;
        }
      }
    }

    tbody {
      padding: 1rem;

      tr {
        background-color: $color-white;
        border: 1px solid black;
        // &.stripped {
        //   &:nth-child(even) {
        //     background: $color-gray-7;
        //   }
        // }

        td {
          padding: 1rem;
          background-color: inherit;
          border-bottom: inherit;
        }
        &.hasHover {
          &:hover {
            background: $color-navy;
            color: $color-white;
            td {
              background: inherit;
              color: inherit;

              > div,
              span {
                color: $color-white;
              }
              i,
              button {
                color: $color-white;
              }
            }
          }
        }

        &:last-child td {
          border-bottom: 0;
        }
      }
    }

    tfoot td {
      border-bottom: 0;
      font-weight: bold;
      text-align: center;
    }
  }

  .tableXs {
    thead {
      td,
      th {
        padding: 0.5rem 0.125rem;
      }
    }

    tbody {
      padding: 0.125rem;
      tr {
        td {
          padding: 0.25rem 0.125rem;
        }
      }
    }
  }

  .iconCaretUp {
    &::after {
      content: '\e993';
      font-family: 'micro-interaction-icons';
      font-size: 1rem;
      color: '#f5f5f5';
      margin-bottom: 15px;
      display: inline-block;
      top: 0px;
      position: relative;
      padding: 0;
      margin: 0;
    }
  }

  .iconCaretDown {
    &::after {
      content: '\e992';
      font-family: 'micro-interaction-icons';
      font-size: 1rem;
      color: '#f5f5f5';
      display: inline-block;
      top: -5px;
      position: relative;
      margin: 0;
    }
  }
  svg {
    fill: '#f5f5f5';
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.125rem;
    border-radius: 0.25rem;
    align-items: baseline;

    .pageNumber {
      display: flex;
      align-items: baseline;

      span {
        margin: 0 0.25rem;
      }
    }

    .controls {
      .prev {
        background-color: #fff;
        color: #424242;
        padding: 0.5rem 1rem;
        border: 0;
        &::before {
          content: '\e9ea';
          font-family: 'micro-interaction-icons';
          color: #424242;
        }

        &:disabled {
          color: #ccc;

          &::before {
            color: #ccc;
          }
        }
      }
      .next {
        background-color: #fff;
        padding: 0.5rem 1rem;
        color: #424242;
        border: 0;
        top: 1px;
        &::after {
          content: '\e9eb';
          font-family: 'micro-interaction-icons';
          color: #424242;
        }

        &:disabled {
          color: #ccc;

          &::after {
            color: #ccc;
          }
        }
      }
    }
  }
  .downloadCsv {
    text-align: right;
    .button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      color: $color-white;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;

  grid-template-areas:
    'rate-panel'
    'health-panel';
  .ratePanel {
    grid-area: rate-panel;
  }
  .healthPanel {
    grid-area: health-panel;
  }
  .countymapheader
  {
    height: 34px;
    left: 60px;
    top: 874px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: #424242;
  }
  .perc
  {
    width: auto;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #424242;
  }
  .maindivPerc{
    width: 530px;
    height: 44px;
    left: 148px;
    top: 919px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #424242;
    margin-top: 11px;
  }
  .outputtitle{
    width: 344px;
    height: 29px;
    left: 60px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #424242;
    margin-top: 21px;
  }
  .ddl{
    width: 100%;
    height: 35px;
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-top: 4px;
    width: 250px !important;
    height: 45px !important;
    left: 1197px !important;
    top: 133px !important;
    background: #FFFFFF !important;
    white-space: nowrap !important;
    font-size: 14px;
    font-family: 'graphik';
    border-radius: 4px;
    margin-left: 43px;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 6fr 6fr;
    grid-template-areas: 'rate-panel health-panel';
    font-size: 12px;
    margin-top: 15px;
    .ratePanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      box-shadow: 0 0 4px 0 #888888;
     
      Span {
        font-size: 12px;
        padding-left: 10px;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }


    .healthPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      box-shadow: 0 0 4px 0 #888888;
      margin-top: 50px;
      Span {
        font-size: 12px;
        padding-left: 10px;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }

      
    }
  }
  .App {
    margin: 20px;
    max-width: 600px;
    width: 38%;
    font-size: 13px;
    font-weight: 400;   
    line-height: 15px;
  }
  .dropdown {
    max-width: 400px;
    
  }
}

@import '../../styles/colors.scss';

.base {
  align-items: center;
  display: inline-flex;

  > input {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: center;
    background-repeat: no-repeat;
    border: 0.0625rem solid $color-gray-1-1;
    height: 23px;
    outline: none;
    transition: all 300ms ease-in-out;
    width: 23px;

    &:indeterminate {
      border-color: $color-blue-2;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0iIzMxNmJiZSI+PHBhdGggZD0iTTIsMCBMMjIsMCBDMjMuMTA0NTY5NSwtMi4wMjkwNjEyNWUtMTYgMjQsMC44OTU0MzA1IDI0LDIgTDI0LDIyIEMyNCwyMy4xMDQ1Njk1IDIzLjEwNDU2OTUsMjQgMjIsMjQgTDIsMjQgQzAuODk1NDMwNSwyNCAxLjM1MjcwNzVlLTE2LDIzLjEwNDU2OTUgMCwyMiBMMCwyIEMtMS4zNTI3MDc1ZS0xNiwwLjg5NTQzMDUgMC44OTU0MzA1LDIuMDI5MDYxMjVlLTE2IDIsMCBaIE00LDExIEw0LDEzIEwyMCwxMyBMMjAsMTEgTDQsMTEgWiIgLz48L3N2Zz4=);
    }

    &:checked {
      border-color: $color-blue-2;
      background-size: 23px 23px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0iIzMxNmJiZSI+PHBhdGggZD0iTTIsMCBMMjIsMCBDMjMuMTA0NTY5NSwtMi4wMjkwNjEyNWUtMTYgMjQsMC44OTU0MzA1IDI0LDIgTDI0LDIyIEMyNCwyMy4xMDQ1Njk1IDIzLjEwNDU2OTUsMjQgMjIsMjQgTDIsMjQgQzAuODk1NDMwNSwyNCAxLjM1MjcwNzVlLTE2LDIzLjEwNDU2OTUgMCwyMiBMMCwyIEMtMS4zNTI3MDc1ZS0xNiwwLjg5NTQzMDUgMC44OTU0MzA1LDIuMDI5MDYxMjVlLTE2IDIsMCBaIE05LjM2Mzk2MTAzLDE2LjU1NjM0OTIgTDQuNDE0MjEzNTYsMTEuNjA2NjAxNyBMMywxMy4wMjA4MTUzIEw5LjM2Mzk2MTAzLDE5LjM4NDc3NjMgTDIxLjM4NDc3NjMsNy4zNjM5NjEwMyBMMTkuOTcwNTYyNyw1Ljk0OTc0NzQ3IEw5LjM2Mzk2MTAzLDE2LjU1NjM0OTIgWiIgLz48L3N2Zz4=);
    }

    &:hover {
      border-color: $color-blue-2;
    }

    &:focus {
      border-color: $color-blue-2;
      box-shadow: 0 0 0 0.1875rem $color-blue-3;
    }

    + span {
      margin-left: 0.25rem;
      margin-right: 0.5rem;
    }
  }
}

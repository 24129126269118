@import '../../styles/colors.scss';

.base {
  display: grid;
  // gap: 20px;
  grid-template-areas:
    'first-panel'
    'second-panel'
    'third-panel'
    'fourth-panel'
    'fifth-panel'
    'six-panel';

  .demographyPanel {
    grid-area: first-panel;
  }
  .lifePanel {
    grid-area: second-panel;
  }
  .demographyFilterPanel {
    grid-area: third-panel;
  }
  .lifeFilterPanel {
    grid-area: fourth-panel;
  }
  .covidPanel {
    grid-area: fifth-panel;
  }
  .covidFilterPanel {
    grid-area: six-panel;
  }
  .hedaerText
  {
    //position: absolute;
    //left: 0%;
    //right: 0%;
    //top: 38.6%;
    //bottom: 0%;
    //border: 1px solid #979797;
    height: 35px;
    width: 115px;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 6fr 6fr;
    grid-template-areas:
      'first-panel second-panel '
      'third-panel fourth-panel '
       'fifth-panel fifth-panel'
       'six-panel six-panel';
    font-size: 12px;
    .demographyPanel,
    .covidPanel,
    .lifePanel {
      padding: 0.5rem 0.5rem;
      .filterTitle {
        font-size: 12px;
        font-style: italic;
      }
    }
    .demographyFilterPanel,
    .lifeFilterPanel,
    .covidFilterPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      .dateWeek {
        padding-left: 2rem;

        .position {
          padding: 0;
          margin: 0;
        }
        .headerSpan {
          font-size: 14px;
          font-weight: bold;
        }
        .selectList {
          width: 130px;
          height: 30px;
        }
      }
    }
   
  }

  .illness {
    padding: 0px 0px 0px 5px;    
    
  }
}

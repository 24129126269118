@import '../../../../styles/colors.scss';

.base {
  .header {
    display: block;
    background: $color-cloud;

    .content {
      padding: 0 2.5rem 4rem;      
    }    
  }
  .body {
    //padding: .5rem .5rem 0rem 0rem;
    min-height: 40vh;    
    .details form {
      h4 {
        display: block;
        margin-bottom: 0.25rem;
        text-transform: uppercase;
        color: #424242;
        font-family: Graphik-semibold, Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 3px;
      }

      // > div {
      //   margin-bottom: 1rem;
      // }

      //margin-bottom: 5rem;
    }
  }
}

.details form {
  h4 {
    display: block;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    color: #424242;
    font-family: Graphik-semibold, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 3px;
  }

  > div {
    margin-bottom: 0rem;
  }

  //margin-bottom: 5rem;
}

@import '../../../../styles/colors.scss';

.base {
  min-height: 80vh;

  .header1 {
    padding: 2rem 2rem 2rem;
    // background: $color-cloud;
    color: black;
    margin-top: 10px;
    // height: 100px;

    .forecastAcc1 {
      display: flex;
      padding-left: 10px;
      .headingDiv {
        h1 {
          margin-top: 10px;
        }
      }
      .accDiv {
        height: 18px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #424242;
        margin-left: 10px;
      }
    }
  }
  .header {
    display: block;
    background: $color-cloud;
    color: black;
    margin-top: 14px;
    height: 112px;
    .content {
      padding: 2rem 2.5rem 2rem;
      max-width: auto;
    }
  }

  .body {
    padding: 1rem 2.5rem 4rem;
    min-height: 70vh;
  }

  div {
    &.chartDiv {
      margin-bottom: 2rem;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 16px;
      // width: 1390px;
      // height: 642px;
      left: 64px;
      top: 217px;
      background: #ffffff;
      // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
      margin-top: 4px;
      h4 {
        padding-right: 10px;
      }
      div {
        &.container {
          display: flex;
          margin-top: 14px;
        }
      }
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }

  .forecastAcc {
    // width: 252px;
    height: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #424242;
    margin-left: 314px;
    margin-top: -65px;
  }
  .forecastAcc2 {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 24px;
    box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
    background: #ffffff;
  }
  .divddl {
    margin-bottom: 2px;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background-color: rgb(255, 255, 255);
    // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  }
  .divMain {
    box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  }
  .bottomText {
    width: 342px;
    height: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #424242;
  }
}

@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas:
    'title-panel'
    'count-panel';
  .titlePanel {
    grid-area: title-panel;
  }
  .countPanel {
    grid-area: count-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 3fr 9fr;
    grid-template-areas: 'title-panel count-panel';
    font-size: 12px;
    .titlePanel {
      padding: 0.5rem 0.5rem;
      h4 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .countPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      // background-color: $color-cloud;
      .dateWeek {
        padding-left: 2rem;

        .headerSpan {
          font-size: 12px;
        }
        .dataSpan {
          font-size: 12px;
          strong {
            font-size: 18px;
          }
        }
        .sourceSpan {
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
}

@import '../../styles/colors.scss';

.base {
  color: #424242; //$color-blue-1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  //margin-left: 650px;

  .icon {
    i {
      vertical-align: middle;
    }
  }
  .dateWeek {
    padding-left: 2rem;
    padding-top: 1.5rem;
    .infoIcon {
      display: flex;
    }
    .position {
      padding: 0;
      margin: 0;
      opacity: 0.6;
      text-transform: uppercase;
    }

    .positionButton {
      background-color: #fff;
      padding: 2px 6px;
      border-radius: 4px;
      cursor: pointer;
      opacity: 1;
      border: 0px;
      text-transform: uppercase;
    }
    .displayDateMargin {
      margin-left: 450px;
      // width: 50px;
      //height: 19px;
      // left: 41.21%;
      // right: 41.14%;
      // top: calc(50% - 19px / 2 - 31px);
      font-family: 'graphik';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #424242;
    }
    // .displayDateMargin {
    //   margin-left: 550px;
    //   width: 50px;
    //   height: 19px;
    //   left: 41.21%;
    //   right: 41.14%;
    //   top: calc(50% - 19px / 2 - 31px);
    //   font-family: 'graphik';
    //   font-style: normal;
    //   font-weight: 700;
    //   font-size: 16px;
    //   line-height: 19px;
    //   color: #424242;
    // }
    .test {
      height: 0px;
    }
    .aboutThis {
      // height: 16px;
      // left: 23.17%;
      // right: 0%;
      // top: calc(50% - 16px / 2);
      font-family: 'graphik';
      font-size: 16px;
      line-height: 16px;
      text-decoration-line: underline;

      margin-left: 10px;
      padding-top: 2px;
      a {
        color: rgb(0, 38, 119);
      }
    }
    // .aboutThis{
    //   width: 42px;
    // height: 20px;
    // left: 48px;
    // top: 18px;

    // font-style: normal;
    // font-weight: 500;
    // font-size: 16px;
    // line-height: 20px;
    // text-decoration-line: underline;
    // color: #003C71;
    // }
    .setHeight {
      height: 1px; // Not required to show this section on UI
    }
    .forecastedData {
      height: 14px;
      /* left: 92.8%; */
      /* right: 0.61%; */
      top: calc(50% - 14px / 2 - 40.5px);
      font-family: 'graphik';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #424242;
      margin-left: 20px;
    }
    .claimsData {
      height: 14px;
      left: 81.74%;
      right: 13.33%;
      top: calc(50% - 14px / 2 - 40.5px);
      font-family: 'graphik';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #424242;
      margin-left: 280px;
    }
  }
}

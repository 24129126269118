@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;

  grid-template-areas:
    'rate-panel '
    'rate-list'
    'health-panel';
  .ratePanel {
    grid-area: rate-panel;
  }
  .rateList{
    grid-area:  rate-list;
  }
  .healthPanel {
    grid-area: health-panel;
  }
}
.modal {
  padding-top: 1rem;
  width: 100%;
  height: 100%;

}

.export {
  width: 64%; 
  border: 1px solid #424242;
  margin-top: 25px;
  text-align: center;
  margin-left: 105%;
  margin-top: -26px;
  display: block;
}
@media (min-width: 1000px) {
  .base {
    grid-template-columns: 6fr 6fr;
    grid-template-areas: 'rate-panel rate-list'
    'health-panel .'  ;
    font-size: 12px;
    margin-top: 15px;
    .ratePanel,.rateList,
    .healthPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      box-shadow: 0 0 4px 0 #888888;
      Span {
        font-size: 18px;
         padding-left: 5px;
        Span {
          font-size: 12px;
          padding-left: 5px;
        }
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }
}

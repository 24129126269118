@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas: 'title-panel';
  .titlePanel {
    grid-area: title-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 12fr;
    grid-template-areas: 'title-panel';
    padding: 2rem 2rem 0rem;
    font-size: 12px;
    .titlePanel {
      // padding: 0 1rem 0rem;
      h4 {
        font-size: 21px;
        span {
          font-size: 10px;
          font-weight: normal;
        }
        margin-bottom: 0px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@import '../../../../styles/colors.scss';

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: white;
    color: #424242;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    border: 1px solid #424242;
    text-align: justify;
    margin: 5px;
    padding-left: 5px;
    font-size: 11px;
    font-family: 'graphik';
    text-transform: none;
    font-weight: 500;
    background: #fefefe;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

.icon {
    color: white;
    cursor: pointer;
    font-size: 20px;
    vertical-align: middle;
    border-color: darkblue;
    background: #002677;
    border-radius: 11px;
  
    &:hover {
      // color: $color-blue-1;
      color: white;
    }
  }
@import '../../styles/colors.scss';

.base {
  // width: 1050px;
  // height: 570px;
  padding: 1rem;
  background-color: #fff;
  overflow: hidden;
  .heading {
    text-align: center;
    color: #999;
    line-height: 0.9rem;
    margin-bottom: 10px;
  }

  .container {
    display: flex;
    flex-direction: row;
    .map {
      width: 100%;

      .legend {
        position: absolute;
        z-index: 10;
        background-color: #fff;
        padding: 10px;
        margin: 1px;
        top: 120px;
      }
    }
    .rightPanel {
      width: 30%;
    }
  }

  .timeline {
    width: 100%;
    height: 40px;
    background-color: #efefef;
  }

  .footer {
    text-align: center;
    color: #999;
    font-size: 0.75rem;
    line-height: 0.9rem;
    margin-top: 10px;
  }
}

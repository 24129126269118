@import '../../../styles/colors.scss';

.base {
  .header {
    display: block;
    background: $color-cloud;
    margin-bottom: 2rem;

    .content {
      padding: 1rem;
    }
  }

  .to {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
  }

  .subject {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
  }

  .body {
    border: 1px solid #ccc;
  }
}

@import '../../styles/colors.scss';

.base {
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  // background-color: $color-cloud;

  .dropDowns {
    display: inline;
  }

  .selectorDiv2 {
    margin-left: 1rem;
  }
  .selectorDiv3 {
    // margin-top: 2.5%;
    // margin-left: 1rem;
    margin-top: 35px;
    margin-left: 1rem;
    height: 30px;
  }
}

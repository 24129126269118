@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 0px;
  grid-template-areas:
    'map';
  .map {
    grid-area: map;
    // border-top: 2px solid rgb(156, 156, 156);
    > svg {
      position: absolute;
      background: #fff;
      z-index: 10;
      padding: 10px;
    }
  }
  .head
  {
    margin-top: 12px;
    width: 725px;
    height: 22px;
    left: 75px;
    top: 248px;
    font-family: Graphik !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #424242;
    margin-left: 18px;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 12fr;
    grid-template-areas:
      'map';
    .map {
      min-height: 40vh;
      background-color: $color-gray-3;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  border-right: 0.0625rem solid $color-gray-5;
  font-size: 1rem;
  padding: 0 1rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0;
    padding-right: 0;
  }

  %common-styles {
    align-items: center;
    display: inline-flex;
    // font-weight: bold;
    font-weight: 500;
    padding: 0;
    font-size: 0.875rem !important;
  }

  > span {
    > a {
      @extend %common-styles;
      text-decoration: none;
    }

    > button {
      @extend %common-styles;
      border: 0;
      font-family: inherit;
      font-size: inherit;
      // background-color: #fff;
      color: #424242;
    }
  }
}

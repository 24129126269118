@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas: 'title-panel model-panel';
  .titlePanel {
    grid-area: title-panel;
  }
  .modelPanel {
    grid-area: model-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 6fr 6fr;
    grid-template-areas: 'title-panel model-panel';
    padding: 2rem 2rem 0rem;

    .titlePanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .titleData {
        padding: 1rem;
        h1 {
          margin-top: 15px;
          font-size: 21px;
          font-weight: bold;
        }
      }
      .headData {
        background-color: $color-cloud-backgroud;
        padding: 1rem;
        margin-left: 10px;
        text-align: center;
        .position {
          font-size: 14px;
          padding: 0;
          margin: 0;
        }
        .headerSpan {
          font-size: 14px;
          font-weight: bold;
        }
      }
      // h4 {
      //   font-size: 21px;
      //   span {
      //     font-size: 10px;
      //     font-weight: normal;
      //   }
      //   margin-bottom: 0px;
      // }
      // p {
      //   font-size: 14px;
      // }
    }
  }
}

@import '../../styles/colors.scss';

.base {
  display: grid;
  // gap: 10px;

  grid-template-areas:
    'rate-panel '
    'rate-list'
    'health-panel'
    'health-title'
    'rate-title';
  .ratePanel {
    grid-area: rate-panel;
  }
  .rateList {
    grid-area: rate-list;
  }
  .healthPanel {
    grid-area: health-panel;
  }
  .healthTitle {
    grid-area: health-title;
  }
  .rateTitle {
    grid-area: rate-title;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 6fr 6fr;
    grid-template-areas:
      'rate-title health-title '
      'rate-panel rate-list ';
    font-size: 12px;
    padding: 1rem 1rem 0rem;
    .ratePanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      // border-left: 1px solid #dadbdc;
      // box-shadow: 0 0 4px 0 #888888;
      Span {
        font-size: 18px;
        padding-left: 10px;
        Span {
          font-size: 12px;
          // padding-left: 10px;
        }
      }
      .graphSource {
        font-size: 12px;
        .spanStyle {
          font-size: 12px;
          font-weight: 600;
          padding-left: 0px;
        }
      }
    }
    .rateList {
      display: none;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      // border-left: 1px solid #dadbdc;
      // box-shadow: 0 0 4px 0 #888888;
      Span {
        font-size: 18px;
        padding-left: 10px;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    .healthTitle {
      display: none;
      padding: 0.5rem 0.5rem;
      p {
        font-size: 20px;
        padding-left: 10px;
        font-weight: bold;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
    }
    .rateTitle {
      // border-left: 1px solid #dadbdc;
      padding: 0.5rem 0.5rem;
      p {
        font-size: 20px;
        padding-left: 10px;
        font-weight: bold;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
    }
  }
}

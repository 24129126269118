@import '../../styles/colors.scss';

.base {
  .header {
    display: block;

    padding-top: 0.5rem;
    border-top: 3px solid $color-cloud;
    background: $color-white;

    .content {
      padding: 0 2.5rem 4rem;
      .lineHeading {
        margin-top: 1rem;
        margin-bottom: 2px;
        font-size: 14px;
        font-weight: bold;
      }
      .leftDiv {
        display: flex-left;
      }
      .rightDiv {
        display: flex-right;
      }
    }
  }

  .body {
    padding: 2rem 2.5rem 4rem;
    min-height: 70vh;
  }
}

@import '../../styles/colors.scss';

.base {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0.25rem;

  > li {
    align-items: center;
    background: $color-gray-7;
    border: 0.0625rem solid $color-gray-4;
    border-radius: 0.125rem;
    color: $color-blue-2;
    cursor: default;
    display: inline-flex;
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin: 0.25rem;
    outline: none;
    overflow: hidden;
    padding: 0.5rem;

    &:focus {
      border-color: $color-blue-2;
      box-shadow: 0 0 0 0.1875rem $color-blue-3;
      z-index: 100;
    }

    > strong {
      margin-right: 0.5rem;
    }

    .remove {
      cursor: pointer;
      display: inline-flex;
      margin: -0.5rem;
      margin-left: 0.5rem;
      padding: 0.5rem;

      &:hover {
        background: $color-blue-2;
        color: $color-white;
      }

      &:active {
        background: $color-blue-1;
        color: $color-white;
      }
    }
  }
}

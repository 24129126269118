@import '../../styles/colors.scss';

.base {
  align-items: flex-end;
  display: inline-flex;
  margin-top: 1rem;

  a {
    align-items: flex-end;
    color: $color-blue-2;
    display: inline-flex;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $color-blue-1;
    }

    > i {
      margin-right: 0.5rem;
    }
  }

  > i {
    margin: 0 0.5rem;
  }
}

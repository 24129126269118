@import '../../styles/colors.scss';

.base {
  //background: $color-navy;
  //background: linear-gradient(to right, #01396d 1%, #00274a 99%);

  .items {
    display: flex;
    list-style: none;
    padding: 0;
    margin-left: -27px;
    justify-content: flex-end;
  }
  .containerPadding {
    padding: 0px 0px !important;
  }
}

@import '../../../styles/colors.scss';

.kpisDiv {
  display: flex;
  font-size: 12px;
  padding: 10px 10px 0px;
  .kpisDivLeft {
    width: 48%;
    .val {
      font-size: 16px;
      font-weight: 400;
      color: $color-gray-1;
    }
    // display: flex;
    //justify-content: flex-start;
  }
  .kpisDivRight {
    width: 50%;
    // display: flex;
    //justify-content: flex-end;
  }
}

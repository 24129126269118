@import '../../../../styles/colors.scss';

.base {
  .header {
    display: block;
   // background: $color-cloud;
   margin-top: 20px;
    background:#ffffff;
    .content {
      padding: 0 1.5rem 0rem;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .body {
    padding: 2rem 2.5rem 4rem;
    min-height: 70vh;
    background:#ffffff;
  }

  h3 {
    font-weight: normal;
  }

  .margin {
    margin-right: 0.5rem;
  }

  .detailsFlex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .details form {
    h4 {
      display: block;
      margin-bottom: 0.25rem;
      text-transform: uppercase;
      color: #424242;
      font-family: Graphik-semibold, Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 3px;
    }

    > div {
      margin-bottom: 1rem;
    }

    margin-bottom: 5rem;
  }
}

@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;

  grid-template-areas: 'genderage-panel';

  .genderPanel {
    grid-area: genderage-panel;
  }
}

.genderMale {
  width: 16px;
  height: 16px;
  //margin-left: 10px;
  top: 335.05px;
  //background: #15A796;
  border-radius: 4px;
  margin-bottom: 0.5%;
}

.genderFemale {
  width: 16px;
  height: 16px;
  //margin-left: 10px;
  top: 361.05px;
  // background: #c71e83;
  //background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAAXNSR0IArs4c6QAAAC5JREFUGFdjZGBgYNjMsPk/iEYGjCBBXwZfMI0hARJAl0RRiSyJYQRMEkMCZiwAF3cbAAnIQsEAAAAASUVORK5CYII=');
  background-repeat: repeat;
  border-radius: 4px;
  margin-bottom: 1%;
}
.captionFormat {
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.25px;
  line-height: 24px;
  color: #002677;
  margin-left: 10px;
}
.subCaptionFormat {
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4b4d4f;
  margin-left: 10px;
}
.textFormat {
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #4b4d4f;
  // text-align: center;
  width: 4%;
  margin-left: 1%;
}
.valueFormat {
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4b4d4f;
  width: 8%;
  text-align: center;
  margin-right: 2.2%;
}
.maindivFormat {
  border: 1px solid #cbcccd;
  border-radius: 4px;
  margin-bottom: 10px;
}

@import '../../styles/colors.scss';


.base {
  font-family: "Optum Sans";
  line-height: 1.5rem;
  //min-width: 1272px;
  min-width: 1160px;
  margin: 0px auto;
  padding: 2rem 2rem 2rem 2rem;
  blockquote,
  ul,
  ol,
  p {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $color-blue-2;
    text-decoration: none;

    &:hover {
      color: $color-blue-1;
      text-decoration: underline;
    }
  }

  blockquote {
    border-right: 0.25rem solid $color-gray-5;
    padding: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.25em;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 1.875rem;
    font-weight: 700;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  h4 {
    font-size: 1.125rem;
    font-weight: 700;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  h6 {
    font-size: 0.75rem;
    font-weight: 100;
  }

  // ul,
  // ol {
  //   margin-left: 3rem;
  // }
}

@import '../../styles/colors.scss';

.base {
  font-family: 'graphik';
  padding: 0rem 0rem 0.21rem 0rem;
  display: inline-block;
  width: 85px; 
  margin-block: 0.1rem;
  cursor: default;
  font-weight: 500;
  font-size: 16px;
}

// .L13 {
//   color: #8A1739;
//   //background-color: rgb(76, 0, 115);
// }

// .L12 {
//   color: #8A1739;
//   //background-color: rgb(110, 0, 0);
// }

// .L11 {
//   color: #8A1739;
//   //background-color: rgb(150, 0, 0);
// }

// .L10 {
//   color: #DD6052;
//   //background-color: rgb(163, 42, 46);
// }

// .L9 {
//   color: #DD6052;
//  // background-color: rgb(250, 79, 0);
// }

// .L8 {
//   color: #DD6052;
//  // background-color: rgb(252, 130, 0);
// }

// .L7 {
//   color: #FFA83E;
//   //background-color: rgb(252, 177, 0);
// }

// .L6 {
//   color: #FFA83E;
//   //background-color: rgb(247, 223, 0);
// }

.L5 {
  color: #C40000;
  //background-color: rgb(255, 235, 0);
}

.L4 {
  color: #FB8D7B;
  //background-color: rgb(175, 229, 45);
}

.L3 {
  color: #4B4D4F;
  //background-color: rgb(28, 243, 28);
}

.L2 {
  color: #6CB2A2;
 // background-color: rgb(15, 165, 15);
}

.L1 {
  color: #218371;
  //background-color: rgb(18, 102, 18);
}

@import '../../styles/colors.scss';

.base {
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  //background-color: $color-cloud; //Commented by Avanish

  > div {
    margin-left: 1rem;
  }

  .dropDowns {
    // display: inline;

    // line-height: 16px;
    // color: #424242;
    font-family: 'graphik';
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 8px 16px;
    gap: 8px;
    // width: 152px;
    height: 40px;
    // background: #ffffff;
    border: 1px solid #999999;
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .showme {
    width: 25%;
  }
  .display {
     width: 65%;
  }
  .displayHRR {
    width: 82%;
  }
  .displayFlu {
    width: 82%;
  }
  .exportHRR {
    width: 14%;
    height: 40px;
    border: 1px solid #424242;
    margin-top: 25px;
    text-align: center;
    line-height: 35px;
    color: '#424242';
    display: none;
  }
  .export {
    // position: absolute;
    width: 10%;
    height: 34px;
    // left: 1373px;
    // top: 137px;
    border: 1px solid #424242;
    margin-top: 30px;
    text-align: center;
    line-height: 30px;
    color: '#424242';
    display: none;
  }
  .exportFlu {
    width: 14%;
    height: 40px;
    border: 1px solid #424242;
    margin-top: 25px;
    text-align: center;
    line-height: 35px;
    display: none;
  }
}

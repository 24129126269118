@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas:
    'title-panel'
    'count-panel'
    'filter-panel';
  .titlePanel {
    grid-area: title-panel;
  }
  .countPanel {
    grid-area: count-panel;
  }
  .filterPanel {
    grid-area: filter-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 1fr 5fr 5fr ;
    grid-template-areas: 'title-panel count-panel filter-panel';
    font-size: 14px;
    margin-top:15px;
    .titlePanel {
      h4 {
        width: -1px;
        height: 32px;
        font-style: normal;
        font-weight: 700;
        font-size: 22.78px;
        color: #424242;
        span {
          font-size: 10px;
          font-weight: normal;
        }
      }
      p{
        width: 339px;
    height: 42px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    
      }
    }
    .countPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      background-color: $color-cloud;
      .dateWeek {
        padding-left: 2rem;
        .position {
          font-size: 14px;
          padding: 0;
          margin: 0;
        }
        .headerSpan {
          font-size: 14px;
          font-weight: bold;
        }
      
            } 
            .positionvs{              
              line-height: 45px;   
              margin-right: -22px;
              margin-left: 11px;
                  }     
    }
    .filterPanel {
    flex-direction: row;
    align-items: center;
    padding: 16px;
    width: 264px;
    height: 45px;
    background: #FFFFFF;
    // box-shadow: 0px 0px 4px rgb(0 0 0 / 50%);
    box-shadow: 0px 0px 4px #09090987
   
    }
    .arrow{
      height: 15px;
    width: 20px;
    float: right;
    display: block;
    margin-right: 3px;
    margin-top: -29px;
    }
    .filterdivpopup{
      position: absolute;
      background-color: white;
      padding: 7px 15px;
      z-index: 1;
      width: 21%;
      border: 1px solid;
      margin-top: 16px;
      margin-left: -16px;
      box-shadow: 0px 0px 4px #09090987;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  &.active {
    color: $color-white;

    > span {
      > a,
      > a:hover,
      > button,
      > button:hover {
        color: #424242; //$color-white;
        cursor: default;

        &::after {
          width: 100%;
        }
      }
    }
  }

  %common-styles {
    box-sizing: border-box;
    color: #424242; //$color-white;
    cursor: pointer;
    font-size: 0.770rem;
    // padding: 1rem 1.5rem 0rem;
    padding: 1rem 0.5rem 0rem;
    transition: color 0.2s ease-in-out;
    font-weight: 500;
    line-height: 1.5rem;

    &::after {
      display: block;
      content: '';
      border-top: 6px solid #e40046;
      margin-top: 12px;
      width: 0;
      margin-bottom: -12px;
      transition: width 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &:hover {
      color: #424242; //$color-white;
      text-decoration: none;
      &::after {
        width: 100%;
        transition: width 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  > span {
    > a {
      @extend %common-styles;
      text-decoration: none;
    }

    > button {
      @extend %common-styles;
      background: none;
      border: 0;
    }
  }
}
.testDiv {
  background-color: red;
}

@import '../../../styles/colors.scss';
.base {
  // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  // height: 30vh;
  display: flex;
  flex-direction: column;
  // overflow: none;

  .header {
    border-bottom: 2px solid grey;
    // color: $color-white;
    padding: 2rem 0 0;
    .headerSpan {
      font-size: 18px;
      font-weight: 700;
    }
    .viewMore {
      padding-left: 10px;
      color: #003c71;
      text-decoration: underline;
      cursor: pointer;
      // font-weight: 600;
      // margin-bottom: 1rem;
      font-size: 12px;
    }
    .headerPara {
      font-size: 14px;
    }
  }
  .valuePara {
    font-size: 16px;
  }
}
.subbase {
  // padding: 1rem 1rem;
  // border-bottom: 1px solid #efefef;
  .valuePara {
    font-size: 16px;
  }
}
// .base {
//   display: grid;
//   gap: 20px;
//   grid-template-areas:
//     'indicator-panel'
//     'value-panel'
//     'change-panel'
//     'graph-panel';
//   .indicatorPanel {
//     grid-area: indicator-panel;
//   }
//   .valuePanel {
//     grid-area: value-panel;
//   }
//   .changePanel {
//     grid-area: change-panel;
//   }
//   .graphPanel {
//     grid-area: graph-panel;
//   }
// }

// @media (min-width: 1000px) {
//   .base {
//     grid-template-columns: 3fr 1fr 3fr 5fr;
//     grid-template-areas: 'indicator-panel value-panel change-panel graph-panel';
//     font-size: 12px;
//     padding: 1rem 0rem 1rem;
//     .indicatorPanel,
//     .valuePanel,
//     .changePanel,
//     .graphPanel {
//       .headerSpan {
//         font-size: 14px;
//         font-weight: bold;
//         display: flex;
//         .viewMore {
//           padding-left: 10px;
//           color: #003c71;
//           text-decoration: underline;
//           cursor: pointer;
//           font-weight: 600;
//           // margin-bottom: 1rem;
//           font-size: 10px;
//         }
//       }
//       .lineBreak {
//         width: 115%;
//       }
//       p {
//         margin-bottom: 10px;
//       }

//       .tableContent {
//         margin-top: 15px;
//         p {
//           margin-bottom: 30px;
//         }
//       }
//       // .tableContent > p:nth-child(1) {
//       //   margin-top: 5px;
//       // }
//       span {
//         font-size: 12px;
//       }
//       .dropDowns {
//         width: 200px;
//         height: 30px;
//         font-size: 12px;
//       }
//     }
//   }
// }

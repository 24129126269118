@import './colors.scss';

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'graphik';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/GraphikExtralight.woff');
}

@font-face {
  font-family: 'graphik';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/GraphikLight.woff');
}

@font-face {
  font-family: 'graphik';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/GraphikRegular.woff');
}

@font-face {
  font-family: 'graphik';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/GraphikMedium.woff');
}

@font-face {
  font-family: 'graphik';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/GraphikSemibold.woff');
}

@font-face {
  font-family: 'graphik';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/GraphikBold.woff');
}

@font-face {
  font-family: 'graphik';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/GraphikSuper.woff');
}

@font-face {
  font-family: 'micro-interaction-icons';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/micro-interaction-icons.woff');
}

@font-face {
  font-family: 'Optum Sans' ;
  src: local('OptumSans-Regular') ,
    url('../fonts/OptumSans-Regular.woff2') format('woff2') ;
  font-style: normal ;
  font-weight: 400 ;
}
@font-face {
  font-family: 'Optum Sans' ;
  src: url('../fonts/OptumSans-Bold.woff2') format('woff2') ,
    url('../fonts/OptumSans-Bold.ttf') format('ttf') ;
  font-style: normal ;
  font-weight: 700 ;
}
@font-face {
  font-family: 'Optum Sans' ;
  src: url('../fonts/OptumSans-RegularItalic.woff2') format('woff2') ;
  font-style: italic ;
  font-weight: 400 ;
}

@font-face {
  font-family: 'Optum Sans' ;
  src: url('../fonts/OptumSans-BoldItalic.woff2') format('woff2') ;
  font-style: italic ;
  font-weight: 700 ;
}

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  color: $color-gray-1;
  font-family: 'graphik', Arial, Helvetica, sans-serif;
}
.highcharts-root {
  font-family: 'Optum Sans', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 8px !important;
  line-height: 12px;
  text-align: center;
  color: #000000;
}
.highcharts-button-box {
  fill: white;
  border-color: #cbcccd;
}
// TODO: enable this to improve mobile browsing experience!
// html,
// body {
//   overflow-x: hidden;
// }
// body {
//   position: relative;
// }

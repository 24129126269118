@import '../../styles/colors.scss';

.base {
  background: $color-white;
  border: 0.0625rem solid $color-gray-1-1;
  box-sizing: border-box;
  display: block;
  font-family: inherit;
  font-weight: normal;
  font-size: 1rem;
  height: calc(0.0625rem * 2 + 1rem + 0.5rem * 2);
  outline: none;
  padding: 0.5rem;
  transition: all 300ms ease-in-out;

  &:hover {
    border-color: $color-blue-2;
  }

  &:focus {
    border-color: $color-blue-2;
    box-shadow: 0 0 0 0.1875rem $color-blue-3;
  }

  &.error {
    border-color: $color-error-red;
    box-shadow: 0 0 0 0.1875rem lighten($color-error-red, 40%);
  }
}

.errorText {
  color: $color-error-red;
  display: block;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

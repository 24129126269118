@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  // padding: 2rem 1rem 0.5rem 1.5rem;
  padding: 2rem 2rem 0rem;
  // margin-top: -10px;
  grid-template-areas: 'rare-diseases';
  .rarediseases {
    grid-area: rare-diseases;
  }
  .alignText {
    text-align: left;
  }
}

.textFormatHeader {
  //width: 30%;
  //height: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 22.78px;
  //line-height: 32px;
  color: #424242;
  padding-bottom: 1rem;
  padding-left: 6px;
  span{
    font-weight: 700;
    font-size: 16.78px;
  }
}
.textFormat {
  position: absolute;
  width: 80%;
  height: 20px;
  // left: 64px;
  top: 236px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #424242;
  margin-top: 10px;
  // margin-left: -10px;
}

.nameHeader {
  width: 121px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #424242;
}
.td {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #424242;
}
.maindiv {
  display: flex;
  margin-top: 5px;
}
.rowdiv {
  height: 36px;
  left: 0%;
  right: 0%;
  top: calc(50% - 19px / 2 - 7.5px);
  font-size: 12px;
  line-height: 19px;
  color: #424242;
}

.rowdivCli {
  height: 25px;
  left: 0%;
  right: 0%;
  top: calc(50% - 19px / 2 - 7.5px);
  font-size: 12px;
  line-height: 19px;
  color: #424242;
}
.datadiv {
  font-weight: bold;
  line-height: 23px;
}
.datadivCli {
  font-weight: bold;
    line-height: 23px;
    font-size: 12px !important    ;
}
.expanHeaderText {
  margin-top: 5px;
  width: 100%;
  height: 18px;
  left: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #424242;
}
.expanBodyText {
  width: 70%;
  height: 16px;
  left: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #424242;
  margin-top: 5px;
  margin-bottom: 8px;
}
.captionText{
  font-size: 12px;
  margin-left: -10px;
  font-weight: 600;
}

// @media (min-width: 1000px) {
//   .base {
//     //grid-template-columns: 6fr 6fr;
//     grid-template-areas: 'rare-diseases';
//     font-size: 12px;
//     //margin-top: 15px;
//     .rarediseases {
//       // padding: 0.5rem 0.5rem;
//       // box-shadow: 0 0 4px 0 #888888;
//       Span {
//         //font-size: 18px;
//         padding-left: 10px;
//         line-height: 1px;
//         Span {
//           font-size: 12px;
//           padding-left: 10px;
//         }
//       }
//       .graphSource {
//         font-size: 12px;
//         Span {
//           font-size: 12px;
//           font-weight: 600;
//         }
//       }
//     }
//   }
// }

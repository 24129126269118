@import '../../../../styles/colors.scss';

.base {
  // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  // height: 75vh;
  // display: flex;
  // flex-direction: column;
  // overflow: hidden;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  height: 68vh;
  display: flex;
  flex-direction: column;
  width: 125%;
  .header {
    color: #424242;
    padding: 1.5rem 1rem 0.5rem;
    // background-image: $color-gradient-uhg-berry;
    background-color: white;
    position: relative;
    h3 {
      button {
        font-size: 16px;
        margin-right: 90px;
        margin-top: 10px;
        display: contents;
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
    button {
      background: none;
      border: 0;
      cursor: pointer;
      padding: 0.25rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: #003c71;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    h3 {
      padding-right: 1.5rem;
    }

    .position {
      padding: 0;
      margin: 0;
      opacity: 0.6;
      text-transform: uppercase;
    }
  }

  .body {
    overflow-y: scroll;
    height: 100%;
    padding-left: 1rem;
    background-color: white;
    .viewMore {
      color: black;
      text-decoration: underline;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }
  .alignLeft {
    justify-content: flex-end;
  }
  .btn {
    padding-left: 0.5rem;
    cursor: pointer;
    color: $color-blue-2;
    font-size: 12px;
  }

  .checkboxPosition {
    margin-top: 12px;
    margin-left: 15px;
  }
}

@import '../../styles/colors.scss';

.base {
  background: $color-navy;
  background: linear-gradient(to right, #01396d 1%, #00274a 99%);
  min-height: 100vh;

  .container {
    max-width: 102vh;
    margin: 0 auto;
    margin-top: 4rem;
  }

  .logo {
    //padding: 4rem 4rem 2rem;
    padding:2rem 2rem 2rem;
  }

  .loginCard {
    .appName {
      padding: 1rem 1rem 3rem;
      text-align: center;
      font-weight: 700;
      font-size: 1.5rem;
      color:  #323334;
      ;
    }

    .loginPreference {
      padding: 0rem 1rem 2rem;
      text-align: center;
      font-size: 1rem;
      color: #323334;      
      font-weight: 500;
      line-height: 20px;
    }

    .loginBtns {
      > div {
        justify-content: center;
      }
    }
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.base {
  opacity: 1;
  cursor: progress;
  animation: loading ease 1s;
  animation-iteration-count: infinite;
}

.filter {
  width: 119px;
  height: 16px;
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #4b4d4f;
  margin-top: 4px;
}
.ddl {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background: #ffffff;
  border-right: 0px solid #4b4d4f;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 0px;
  cursor: pointer;
  font-family: 'Optum Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #002677;
}
.item {
  font-weight: 400;
  font-size: 16px;
  // top: 5px;
  padding: 8px 16px 8px 16px !important;
}

.menuItemClassName {
  background: #ffffff;
  &:hover {
    color: #4b4d4f;
    background-color: #eef4ff;
    border-right: 1px solid #cbcccd;
  }
}
.dropDownIcon {
  height: 24px;
  width: 24px;
}
.rightIcon {
  margin-left: 16px;
  margin-right: 10px;
}

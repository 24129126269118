@import '../../styles/colors.scss';

.base {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  height: 390px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  .header {
    color: $color-white;
    padding: 1rem 1rem 0;
    background-image: $color-gradient-uhg-berry;
    background-color: $color-navy;

    input {
      outline: 0;
      border: 0;
      transition: all 0.2s ease 0s;
      font-size: 1rem;
      padding: 0 1rem;
      height: 2.5rem;
      width: 100%;
      display: flex;
      position: relative;
      box-sizing: border-box;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  padding: 0.3rem 0.5rem;
  border-bottom: 1px solid #efefef;
  position: relative;
  overflow: hidden;

  button {
    background: none;
    border: 0;
    padding: 0.25rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    left: 1rem;
    color: black;
    width: 60%;
    font-weight: 500;
  }
}

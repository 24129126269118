@import "../../styles/colors.scss";

.base {
  > span {
    display: block;
    position: relative;

    %common-styles {
      color: $color-gray-1;
      cursor: pointer;
      display: block;
      margin-bottom: 0.75rem;
      text-decoration: none;

      &:hover {
        color: $color-gray-2;
      }
    }

    > a {
      @extend %common-styles;
    }
  }
}

@import '../../../styles/colors.scss';

.base {
  // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  // height: 30vh;
  display: flex;
  flex-direction: column;
  // overflow: none;

  .header {
    border-bottom: 2px solid grey;
    // color: $color-white;
    padding: 2rem 0 0;
    .headerSpan {
      font-size: 18px;
      font-weight: 700;
    }
    .headerPara {
      font-size: 14px;
    }
  }
  .valuePara {
    font-size: 16px;
  }
}
.subbase {
  // padding: 1rem 1rem;
  // border-bottom: 1px solid #efefef;
  .valuePara {
    font-size: 16px;
  }
}

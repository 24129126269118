@import '../../styles/colors.scss';

.base {
  background: $color-navy;
  background: linear-gradient(to right, #01396d 1%, #00274a 99%);
  min-height: 100vh;

  .container {
    max-width: 70vh;
    margin: 0 auto;
    margin-top: 4rem;
  }

  .logo {
    padding: 4rem 4rem 2rem;
  }

  .loginCard {
    .appName {
      padding: 1rem 1rem 4rem;
      text-align: center;
      font-weight: 300;
      font-size: 1.5rem;
      color: #424242;
    }

    .loginPreference {
      padding: 0rem 1rem 2rem;
      text-align: center;
      font-size: 1rem;
      color: #424242;
    }
  }
}

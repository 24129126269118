@import '../../styles/colors.scss';

.base {
  width: 95%;
  display: grid;
  gap: 30px;
  grid-template-areas:
    'flu-panel'
    'covid-panel'
    'hrr-panel';
  .covidPanel {
    grid-area: covid-panel;
  }
  .hrrPanel {
    position: relative;
    grid-area: hrr-panel;
  }
  .fluPanel {
    position: relative;
    grid-area: flu-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    width: 94%;
    grid-template-columns: 4fr 4fr 4fr;
    grid-template-areas: 'flu-panel covid-panel hrr-panel ';
    padding: 1rem 2rem 1rem;
    .covidPanel,
    .hrrPanel {
      // margin-left: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0rem;
      background-color: $color-cloud;
      border-radius: 4px;
      position: relative;
      .dashboardCard {
        flex-grow: 4;
        max-width: 100%;
        .dashboardCardHeader {
          padding: 0.5rem 1rem;
          color: #000;
          .mapHeaderDiv {
            display: inline-flex;
            width: 65%;
            // white-space: nowrap;

            font-weight: 600;
            justify-content: flex-start;
            font-size: 16px;
          }
          .mapHeaderValue {
            display: inline-flex;
            width: 35%;
            // white-space: nowrap;

            font-weight: 600;
            justify-content: flex-end;
            font-size: 14px;
          }
        }
      }
      .sourceDiv {
        padding: 0.5rem 1rem;
        .graphSource {
          font-size: 12px;
          Span {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
      // .mapHeaderDiv {
      //   display: 'inline-flex';
      //   width: '50%';
      //   white-space: 'nowrap';
      //   overflow: 'hidden';
      //   font-weight: '600';
      // }
    }
    .fluPanel {
      // margin-left: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0rem;
      background-color: $color-cloud;
      border-radius: 4px;
      .alignRight {
        display: flex;
        justify-content: flex-end;
      }
      .alignLeft {
        display: flex;
        justify-content: flex-start;
      }
      .dashboardCard {
        flex-grow: 4;
        max-width: 100%;
        .dashboardCardHeader {
          padding: 0.5rem 1rem;
          color: #000;
          .mapHeaderDiv {
            display: inline-flex;
            width: 65%;
            // white-space: nowrap;

            font-weight: 600;
            justify-content: flex-start;
            font-size: 16px;
          }
          .mapHeaderValue {
            display: inline-flex;
            width: 35%;
            // white-space: nowrap;

            font-weight: 600;
            justify-content: flex-end;
            font-size: 14px;
          }
        }
      }
      .sourceDiv {
        padding: 0.5rem 1rem;
        .graphSource {
          font-size: 12px;
          Span {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

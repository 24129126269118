@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;

  grid-template-areas:
    'city-panel'
    'age-panel';

  .cityPanel {
    grid-area: city-panel;
  }
  .agePanel {
    grid-area: age-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 6fr 6fr;
    grid-template-areas: 'city-panel age-panel';
    font-size: 12px;
    margin-top: 15px;
    .cityPanel {
      padding: 0.5rem 0.5rem;
      box-shadow: 0 0 4px 0 #888888;
      Span {
        font-size: 18px;
        padding-left: 10px;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    .agePanel {
      Span {
        font-size: 18px;
        padding-left: 10px;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
      .graphSource {
        font-size: 12px;
        Span {
          font-size: 12px;
          font-weight: 600;
        }
      }
      .ageDiv {
        box-shadow: 0 0 4px 0 #888888;
        padding: 0.5rem 0.5rem;
        height: 47%;
      }
      .genderDiv {
        box-shadow: 0 0 4px 0 #888888;
        padding: 0.5rem 0.5rem;
        margin-top: 2%;
        height: 47%;
      }
    }
  }
}

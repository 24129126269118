@import '../../styles/colors.scss';

.base {
  background: $color-white;
  border: 0.0625rem solid $color-gray-5;
  list-style: none;
  margin-top: 0.25rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transform: translateY(-1rem);
  transition: all 0.1s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 10000;
  margin: 0 !important;
  font-family: 'Optum Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  /* identical to box height, or 125% */

  /* neutral/80 */

  color: #4b4d4f;

  &.open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  > li {
    cursor: pointer;
    display: block;
    outline: none;
    padding: 0.5rem 1rem;

    > span {
      box-sizing: border-box;
      display: inline-flex;
      min-width: 240px;
      // padding-right: 2rem;
      text-align: left;
    }

    &:hover,
    &:focus {
      background: $color-cloud;
    }
  }

  .title {
    background-color: black;
    color: white;
    cursor: default;

    .showing {
      padding: 0;
      margin: 0;
      opacity: 0.6;
      text-transform: uppercase;
      font-size: 0.75rem;
    }

    &:hover,
    &:focus {
      background-color: black;
    }
  }
}

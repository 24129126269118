@import '../../styles/colors.scss';

div {
  &.react-datepicker-wrapper {
    width: 100%;
    input {
      padding: 0.5rem;
      background-color: white;
      width: 100%;
      z-index: 999;
      border: 0.0625rem solid #999999;
      font-size: 14.5px;
      &:focus {
        outline: none;
        border-color: $color-blue-2;
        box-shadow: 0 0 0 0.1875rem $color-blue-3;
      }
    }
  }
}

@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 10px;
  grid-template-areas:
    'title-panel'
    'blank-panel'
    'heading1-panel'
    'heading2-panel'
    'flu-panel'
    'respiratory-panel';
  .titlePanel {
    grid-area: title-panel;
  }
  .blankPanel {
    grid-area: blank-panel;
  }
  .heading1Panel {
    grid-area: heading1-panel;
  }
  .heading2Panel {
    grid-area: heading2-panel;
  }
  .fluPanel {
    grid-area: flu-panel;
  }
  .respiratoryPanel {
    grid-area: respiratory-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 3fr 7fr;
    grid-template-areas:
      'title-panel blank-panel'
      'heading1-panel heading2-panel  '
      'flu-panel respiratory-panel ';
    font-size: 12px;
    .heading1Panel,
    .heading2Panel {
      padding: 0.5rem 0.5rem 0rem;
      h4 {
        span {
          font-size: 16px;
          font-weight: bold;
        }
        .viewMore {
          padding-left: 10px;
          color: #003c71;
          text-decoration: underline;
          cursor: pointer;
          font-weight: 400;
          // margin-bottom: 1rem;
          font-size: 12px;
        }
      }
    }
    .titlePanel {
      padding: 0.5rem 0.5rem;
      h4 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .fluPanel,
    .respiratoryPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0rem 0.5rem;
      .dateWeek {
        padding-right: 2rem;

        .headerSpan {
          font-size: 12px;
        }
        .dataSpan {
          font-size: 12px;
          strong {
            font-size: 18px;
          }
        }
        .sourceSpan {
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }
}

@import '../../styles/colors.scss';

.base {

  .body {
    // padding: 2rem 2.5rem 4rem;
    // min-height: 70vh;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  .App {
    margin: 20px;
    max-width: 600px;
  }
  .dropdown {
    max-width: 400px;
  }
}

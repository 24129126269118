@import '../../styles/colors.scss';

.base {
  display: flex;

  label {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-size: 0.875rem;
    font-weight: 700;
    margin-right: 16px;
    color: $color-gray-2;
  }

  .input {
    align-items: center;
    border: 1px solid #4b4d4f;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    position: relative;
    transition: all 300ms ease-in-out;
    background-color: white;
    width: 40vh;
    border-radius: 4px;
    &:hover {
      border-color: $color-blue-2;
    }

    &.focus {
      border-color: $color-blue-2;
      // box-shadow: 0 0 0 0.1875rem $color-blue-3;
      z-index: 100;
    }
  }
}

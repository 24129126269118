@import '../../styles/colors.scss';

.base {
  align-items: center;
  display: inline-flex;
  padding-left: 5px;
  .labelText {
    margin-left: 0.25rem;
    margin-right: 0.5rem;
  }
}

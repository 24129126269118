.spinner {
  animation: animateSpin 0.6s linear infinite;
  border: 0.125rem solid;
  border-color: #ccc;
  border-top-color: #666;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 32px;
  width: 32px;
}

@keyframes animateSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

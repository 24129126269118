@import '../../styles/colors.scss';

.base {
  background-color: $color-gray-7;
  min-height: 80vh;

  .header {
    display: block;

    .content {
      padding: 2.5rem 2.5rem 1rem;
    }
  }

  .body {
    padding: 2rem 2.5rem 4rem;
    min-height: 70vh;
  }

  .card {
    margin-bottom: 2rem;

    .title {
      margin-bottom: 2rem;
    }

    .records {
      .count {
        padding-bottom: 0.5rem;
        font-size: 3rem;
        font-weight: 100;
      }
      color: $color-gray-5;
      padding-bottom: 1rem;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  margin-top: auto;
  background-color: #003c71;
  // padding: 1.5rem;
  padding: 1.5rem 2rem;
  .copyrightdes {
    display: flex;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    flex-grow: 1;
    justify-content: flex-start;
  }
}

.main {
  margin: 2rem 0;
  display: flex;
  margin-left: 6rem;
}

.disclaimer{
  color: white;
  margin-bottom: 1%;
  border-bottom: 2px solid;
  padding-bottom: 10px;
}
.contentFormat{
  font-size: 11px;
    font-style: italic;
    text-align: justify;
}
.copyright {
  display: flex;
  font-weight: 400;
  // padding-top: 2rem;
  // border-top: 0.0625rem solid $color-gray-4;
  font-size: 0.875rem;
  > ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    li {
      padding-right: 2rem;
      a {
        // text-transform: uppercase;
        color: #ffffff;
        text-decoration: none;
        font-size: 16px;
      }
    }
  }
  > div {
    display: flex;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    flex-grow: 1;
    justify-content: flex-start;
  }
  .logo {
    display: inline-flex;

    svg {
      height: 1.7em;
      position: relative;
      top: -3px;
    }

    &.pointer {
      cursor: pointer;
    }
  }
}

@import '../../styles/colors.scss';

.base {
  min-height: 80vh;

  .header {
    display: block;
    background: $color-cloud;
    color: black;
    .content {
      padding: 0 2.5rem 1rem;
    }
  }

  .body {
    padding: 2rem 2.5rem 4rem;
    min-height: 70vh;
  }

  div {
    &.chartDiv {
      margin-bottom: 2rem;
      h4 {
        padding-right: 10px;
      }
      div {
        &.container {
          display: flex;
        }
      }
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }
}

@import '../../../styles/colors.scss';

.base {
  .header {
    display: block;
    background: $color-cloud;
  }
  .body {
    min-height: 70vh;
  }
  .pageHeader {
    width: 100%;
    display: flex;
  }
  .pageHeaderText {
    width: 20%;
    color: #002677;
    font-weight: 700;
    font-size: 22.78px;
    font-style: normal;
    h5{    font-family: "Optum Sans";
      font-size: 22px;
      font-weight: 600;
      color: rgb(0, 38, 119)}
  }
  .geoName{font-family: "Optum Sans",sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
    width: 15%;}
  .pageHeaderTextValue {
    width: 100%;
    display: flex;
    text-align: right;
    font-weight: 700;
    font-size: 12px;
    justify-content: flex-end;
    .natDiv {
      padding-left: 16px;
      padding-right: 16px;
    }
    .stateDiv {
      padding-left: 16px;
      padding-right: 16px;
      border-left: 1px solid #cbcccd;
    }
    .geoDiv {
      padding-left: 16px;
      // padding-right: 16px;
      border-left: 1px solid #cbcccd;
    }

    .firstSpan {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }

    .secondSpan {
      padding: 0 8px 0 8px;
    }
    .forthSpan {
      line-height: 18px;
      font-size: 14px;
      padding-left: 4px;
      > span {
        font-family: 'Optum Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding-left: 4px;
        /* identical to box height, or 129% */

        /* neutral/80 */

        color: #4b4d4f;
      }
    }
    .stdatavalueup {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #c40000;
    }

    .datavaluedown {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: green;
    }
  }
  .pageHeaderSearch {
    text-align: right;
    margin-top: 2rem;

    margin-left: 65%;
    width: 35%;
  }
  .pageHeaderRight {
    width: 80%;
    // display: flex;
    text-align: right;
    font-weight: 700;
    font-size: 12px;
  }
}

@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 10px;
  grid-template-areas: 'map-panel';
  .mapPanel {
    grid-area: map-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 12fr;
    grid-template-areas: 'map-panel';
    // display: none;
    font-size: 12px;
    .mapPanel {
      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        height: 40px;
        background: #f4f4f4;
        order: 4;
        flex-grow: 0;
        .para {
          width: 95%;
          p {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            /* identical to box height, or 125% */

            /* UHC/Neutrals/Gray 8 */

            color: #424242;
          }
        }
        .icon {
          width: 2%;
          cursor: pointer;
        }
      }
      .map {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;
        background: #f4f4f4;
        order: 4;
        flex-grow: 0;
      }
      h4 {
        font-size: 22px;
      }
      p {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}

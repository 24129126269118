@import '../../../../styles/colors.scss';

.base {
  .header {
    display: block;
    background: $color-white;
    margin-top: 20px;
    .content {
      //padding: 0 2.5rem 4rem;
      padding: 0 2.5rem 0rem;
    }
  }

  .body {
    // padding: 0rem 2rem 2.5rem 4rem;
    padding: 0rem 0.5rem 1.5rem 2.5rem;
    min-height: 70vh;
    background: $color-white;
  }

  .cell {
    color: $color-blue-2;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  h3 {
    font-weight: normal;
  }
  .margin {
    margin-right: 0.5rem;
  }
  .detailsFlex {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 0.125rem solid $color-gray-6;
  }
  .details form {
    h4 {
      display: block;
      margin-bottom: 0.25rem;
      text-transform: uppercase;
      color: #424242;
      font-family: Graphik-semibold, Arial, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: 3px;
    }

    > div {
      margin-bottom: 1rem;
    }

    margin-bottom: 5rem;
  }
  .relatedDetails {
    h3 {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 0.125rem solid $color-gray-6;
    }
  }
}

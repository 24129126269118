@import "../../styles/colors.scss";

.base {
  &.columnSelectorDiv {
    margin-left: 2rem;
    padding: 1rem;
    padding-top: 2rem;
    min-width: 8rem;
    border-radius: 0.25rem;

    > div {
      margin-bottom: 0.25rem;
    }
    &.expandTable {
      display: none;
    }
  }
}

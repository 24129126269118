@import '../../../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas:
    'date-panel'
    'filter-panel'
    'map'
    'cbsas-panel'
    'accuracy-panel'
    'timeline-panel';

  .map {
    grid-area: map;

    > svg {
      position: absolute;
      background: #f7f7f7;
      z-index: 10;
      padding: 10px;
      margin-top: 40px;
    }
  }

  .cbsasPanel {
    grid-area: cbsas-panel;
  }

  .datePanel {
    grid-area: date-panel;
  }

  .timelinePanel {
    grid-area: timeline-panel;
  }
  .accuracyPanel {
    grid-area: accuracy-panel;
  }
  // .filterpanel{
  //   grid-area: filter-panel;
  // }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      'date-panel filterpanel'
      'cbsas-panel map'
      'timeline-panel timeline-panel';

    .map {
      min-height: 40vh;
      left: 0%;
      right: 0.21%;
      top: 0%;
      bottom: 0%;
      background: #f7f7f7;
      border: 1px solid #dadbdc;
      margin-left: -22px;
    }

    .datePanel {
      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 22.78px;
        line-height: 30px;
        color: #424242;
      }
      span {
        width: 365px;
        height: 16px;
        left: 0px;
        font-style: normal;
        // font-weight: 500;
        font-size: 14px;
        line-height: 2px;
        color: #424242;
      }
    }
    .timelinePanel {
      grid-area: timeline-panel;
      border: 1px solid rgba(185, 185, 185, 0.418706);
      height: 100px;
      background-color: #fff;
      margin-top: -20px;
    }
    .mapPopup {
      // height: 340px;
      // width: 30%;
      width: 54vh;

      margin-left: 30%;
      z-index: 2;
      position: absolute;
      margin-top: 5px;
      background-color: white;
      box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
    }
  }
}

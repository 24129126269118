@import "../../styles/colors.scss";

.base {
  border-radius: 0.125rem;
  padding: 1rem;

  &.Toastify__toast--error {
    background: $color-error-red;
  }

  &.Toastify__toast--info {
    background: $color-blue-2;
  }

  &.Toastify__toast--success {
    background: $color-success-green;
  }

  .Toastify__toast-body {
    margin-right: 1rem;
  }
}

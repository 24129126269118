@import '../../../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas:
    'first-panel'
    'second-panel'
    'third-panel'
    'fourth-panel';
  .firstPanel {
    grid-area: first-panel;
  }
  .secondPanel {
    grid-area: second-panel;
  }
  .thirdPanel {
    grid-area: third-panel;
  }
  .fourthPanel {
    grid-area: fourth-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 3fr 3fr 3fr 3fr;
    grid-template-areas: 'first-panel second-panel third-panel fourth-panel';
    .firstPanel,
    .secondPanel,
    .thirdPanel,
    .fourthPanel {
      box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
      padding: 1rem 1rem;
      background-image: $color-gradient-uhg-navy;
      background-color: $color-navy;
      color: $color-white;
      display: flex;
      flex-direction: row;
      overflow: hidden;

      .icon {
        i {
          vertical-align: middle;
        }
      }
      .dateWeek {
        padding-left: 2rem;

        .position {
          padding: 0;
          margin: 0;
          opacity: 0.6;
          text-transform: uppercase;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }

        .positionButton {
          background-color: #fff;
          padding: 2px 6px;
          border-radius: 4px;
          cursor: pointer;
          opacity: 1;
          border: 0px;
          text-transform: uppercase;
        }
        .valueFormat{
          font-style: normal;
          font-weight: 700;
          font-size: 22.78px;
          line-height: 32px;
        }
      }
    }
  }
}

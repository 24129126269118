// Primary
$color-white: #ffffff;
$color-orange: #e87722;
$color-gold: #fcae00;
$color-lemon: #ffda03;
$color-navy: #003c71;
$color-cobalt: #0066f5;
$color-emerald: #008703;
$color-cloud: #f7f7f7;

$color-gray-1: #424242;
$color-gray-1-1: #999999;
$color-gray-2: #777777;
$color-gray-3: #eeeeee;
$color-gray-4: #f5f5f5;

$color-gray-5: #e2e2e2;
$color-gray-6: #e0e0e0;
$color-gray-7: #f5f5f5;

// Accent
$color-red-1: #a32a2e;
$color-red-2: #d13f44;
$color-purple-1: #422c88;
$color-purple-2: #8061bc;
$color-teal-1: #007c89;
$color-teal-2: #6fc1b1;
$color-green: #90bc53;

// Specific use
$color-blue-1: #00396c;
$color-blue-2: #316bbe;
$color-blue-3: #83c8ff;
$color-accessible-orange: #c25608;
$color-error-red: #e40046;
$color-success-green: #627d32;

// Gradient
$color-gradient-1: linear-gradient(to right, #c3373f, #e6a30b);
$color-gradient-2: linear-gradient(to right, #422c88, #c7383d);
$color-gradient-3: linear-gradient(to right, #356bbe, #077a6f);
$color-gradient-4: linear-gradient(to right, #055a53, #83b145);
$color-gradient-5: linear-gradient(to right, #01685c, #3bb6a7);
$color-gradient-6: linear-gradient(to right, #114475, #5a8fc3);
$color-gradient-7: linear-gradient(to right, #033465, #8061bc);

// Theme gradients
$color-gradient-optum: linear-gradient(to right, #d13f44, #f2b411);
$color-gradient-uhc: linear-gradient(to right, #003da1, #00a8f7);
$color-gradient-uhg-navy: linear-gradient(to right, #003c71 1%, #00274a 99%);
$color-gradient-uhg-berry: linear-gradient(to right, #e40046, #b60c4f 52%);
$color-primary-blue: #003c71;
$color-cloud-backgroud: #f4f4f4;
$color-backgroud-white: #fff;
$color-primary-pink: #e40046;

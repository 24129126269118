@import '../../styles/colors.scss';

.base {
  align-items: center;
  background: none;
  border: 0;
  color: #424242;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
  // font-weight: bold;

  &:hover {
    color: $color-blue-1;

    .iconContainer svg {
      transform: translateX(6px);
    }
  }

  > i {
    margin: 0.25rem;
  }

  &:disabled {
    color: $color-gray-2;
    &:hover {
      color: $color-gray-1-1;
      cursor: not-allowed;
    }
  }

  &:active {
    color: $color-blue-2;
  }
}

.active {
  color: $color-blue-1;
  border-bottom: 1px solid $color-blue-1;
}

.iconContainer {
  display: inline-block;
  height: 11px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-left: 5px;

  svg {
    left: -6px;
    position: absolute;
    transition: transform 0.33s ease-in-out;
    g {
      fill: #000;
    }
  }
}

.danger {
  color: $color-red-2;

  &:hover {
    color: $color-red-1;
  }

  &:focus {
    outline-color: $color-red-2;
  }
}

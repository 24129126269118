@import '../../styles/colors.scss';

.base {
  .header {
    display: block;
    background: $color-cloud;

    .content {
      padding: 0 2.5rem 4rem;
    }
  }
  .body {
    padding: 2rem 2.5rem 4rem;
    min-height: 70vh;
    .details form {
      h4 {
        display: block;
        margin-bottom: 0.25rem;
        text-transform: uppercase;
        color: #424242;
        font-family: Graphik-semibold, Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 3px;
      }

      > div {
        margin-bottom: 1rem;
      }

      margin-bottom: 5rem;
    }
  }
}

.details form {
  h4 {
    display: block;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    color: #424242;
    font-family: Graphik-semibold, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: 3px;
  }

  > div {
    margin-bottom: 1rem;
  }

  margin-bottom: 5rem;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 0px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: white;
  color: #424242;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  border: 1px solid #424242;
  text-align: justify;
  margin: 5px;
  padding-left: 5px;
  font-size: 11px;
  font-family: 'graphik';
  text-transform: none;
  font-weight: 500;
  background: #fefefe;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.icon {
  color: white;
  cursor: pointer;
  font-size: 20px;
  vertical-align: middle;
  border-color: darkblue;
  background: #002677;
  border-radius: 11px;

  &:hover {
    // color: $color-blue-1;
    color: white;
  }
}
@import '../../../../styles/colors.scss';

.base {
  display: grid;
  gap: 0px;
  grid-template-areas:
    'mapoption-panel'
    'popover-panel'
    'map'
    'cbsas-panel';

  .map {
    grid-area: map;
    border: 2px solid #dadbdc;
    > svg {
      position: absolute;
      // background: #fff;
      z-index: 10;
      padding: 10px;
    }
  }
  .mapoptionPanel {
    grid-area: mapoption-panel;
  }
  .cbsasPanel {
    grid-area: cbsas-panel;
    border: 2px solid #dadbdc;
    padding: 10px 10px 10px 10px;
  }
  .titleLeft {
    grid-area: title-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 1.2fr 3fr;
    grid-template-areas:
      'mapoption-panel popover-panel'
      'cbsas-panel map';

    .map {
      min-height: 40vh;
      background-color: $color-gray-3;
    }

    .titleLeft {
      .text {
        font-size: 16px;
      }
      .subtext {
        font-size: 12px;
      }
    }
  }
}

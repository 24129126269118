@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas: 'chart-panel';
  .chartPanel {
    grid-area: chart-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 12fr;
    grid-template-areas: 'chart-panel ';
    font-size: 12px;
    padding: 1rem 0rem 0rem;
    .chartPanel {
      box-shadow: 0 0 4px 0 #888888;
      padding: 1rem 0rem 1rem;
      .headerSpan {
        font-size: 18px;
        font-weight: 700;
        margin-left: 10px;
      }
      Span {
        font-size: 18px;
        padding-left: 10px;
        Span {
          font-size: 12px;
          padding-left: 10px;
        }
      }
      .sourceDiv {
        float: right;
        padding-right: 20px;
        .graphSource {
          justify-content: 'flex-end';
          font-size: 12px;
          Span {
            padding-left: 2px;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@import '../../../styles/colors.scss';
.base {
  .header {
    display: block;
    background: $color-cloud;

    .content {
      padding: 0 2.5rem 2rem;
    }
  }
  .body {
    padding: 2rem 2.5rem 4rem;
    min-height: 70vh;
  }
}
.modal {
  padding-top: 1rem;
  > div {
    display: flex;
    margin-bottom: 0.25rem;

    div:first-child {
      width: 140px;
      font-weight: 500;
    }
  }
}
.tdFormat{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #424242;  
}
.thFormat{
  width: 10%;
    padding-top: .5%;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 14.22px;
    line-height: 16px;
    color: #424242;
}
.delvalFormat{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #424242;  
  padding-bottom: 1rem;
}
.headerText{
  width: 160px;
  height: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 22.78px;
  line-height: 32px;
}
.headerSubText{
    width: 800px;
    height: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14.22px;
    line-height: 16px;
}
.add{
      height: 15px;
      width: 18px;
      float: right;
      display: block;
      margin-right: 6px;
      //margin-top: 0px;
}

@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas:
    'first-panel'
    'second-panel';
  .firstPanel {
    grid-area: first-panel;
  }
  .secondPanel {
    grid-area: second-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 4fr 8fr;
    grid-template-areas: 'first-panel second-panel';
    font-size: 12px;
    padding: 1rem 0rem 0rem 0rem;
    
    .firstPanel {
    }
    .secondPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      background-color: $color-cloud;
      .dateWeek {
        padding-left: 2rem;

        .position {
          padding: 0;
          margin: 0;
        }
        .headerSpan {
          font-size: 14px;
          font-weight: bold;
        }
      }
      // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
      // padding: 2rem 1rem;

      // color: $color-white;
      // display: flex;
      // flex-direction: row;
      // overflow: hidden;
      // .icon {
      //   i {
      //     vertical-align: middle;
      //   }
      // }
      // .dateWeek {
      //   padding-left: 2rem;

      //   .position {
      //     padding: 0;
      //     margin: 0;
      //     opacity: 0.6;
      //     text-transform: uppercase;
      //   }

      //   .positionButton {
      //     background-color: #fff;
      //     padding: 2px 6px;
      //     border-radius: 4px;
      //     cursor: pointer;
      //     opacity: 1;
      //     border: 0px;
      //     text-transform: uppercase;
      //   }
    }
  }
}

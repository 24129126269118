@import '../../styles/colors.scss';

.base {
  // box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.25);
  // height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header {
    color: $color-white;
    padding: 1.5rem 1rem;
    background-image: $color-gradient-uhg-berry;
    background-color: $color-navy;
    position: relative;

    button {
      background: none;
      border: 0;
      cursor: pointer;
      padding: 0.25rem;
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: white;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    h3 {
      padding-right: 1.5rem;
    }

    .position {
      padding: 0;
      margin: 0;
      opacity: 0.6;
      text-transform: uppercase;
    }
  }

  .body {
    // overflow: scroll;
    height: 100%;
    padding: 1rem;

    .viewMore {
      color: black;
      text-decoration: underline;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }
  .btn {
    padding-left: 0.5rem;
    cursor: pointer;
    color: $color-blue-2;
    font-size: 12px;
  }
}

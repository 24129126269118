@import '../../styles/colors.scss';

// <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><polygon fill="#444444" points="3 5 13 5 8 11" /></svg>

.base {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: $color-white
    url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlnb24gZmlsbD0iIzQ0NDQ0NCIgcG9pbnRzPSIzIDUgMTMgNSA4IDExIiAvPjwvc3ZnPg==)
    no-repeat right 0.5rem center;
  border: 0.0625rem solid $color-gray-1-1;
  box-sizing: border-box;
  display: block;
  font-family: inherit;
  font-weight: normal;
  font-size: 1rem;
  height: calc(0.0625rem * 2 + 1rem + 0.5rem * 2);
  outline: none;
  padding: 0 2rem 0 0.5rem;
  position: relative;
  transition: all 300ms ease-in-out;
  border-radius: 0;

  &:hover {
    border-color: $color-blue-2;
  }

  &:focus {
    border-color: $color-blue-2;
    box-shadow: 0 0 0 0.1875rem $color-blue-3;
  }

  &.error {
    border-color: $color-error-red;
    box-shadow: 0 0 0 0.1875rem lighten($color-error-red, 40%);
  }
}

.errorText {
  color: $color-error-red;
  display: block;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

@import '../../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas:
    'dataset-panel'
    'region-panel'
    'from-panel'
    'to-panel';
  .datasetPanel {
    grid-area: dataset-panel;
  }
  .regionPanel {
    grid-area: region-panel;
  }
  .fromPanel {
    grid-area: from-panel;
  }
  .toPanel {
    grid-area: to-panel;
  }
  .noPanel {
    grid-area: no-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 2fr 2fr 2fr 2fr 4fr;
    grid-template-areas: 'dataset-panel region-panel from-panel to-panel no-panel';
    font-size: 12px;
    padding: 1rem 0rem 1rem;
    .datasetPanel,
    .regionPanel,
    .fromPanel,
    .noPanel,
    .toPanel {
      span {
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        color: #424242;
      }
      .dropDowns {
        width: 230px;
        height: 30px;
        font-size: 12px;
      }
      .select {
        width: 230px;
        height: 30px;
        font-size: 12px;
      }
      .calenderDiv {
        border: 1px solid;
        width: 100%;
        height: 32px;
        border-radius: 5px;
        border-color: hsl(0, 0%, 80%);
        padding-left: 5px;
        padding-top: 5px;
        .calenderLabel {
          vertical-align: center;
          font-size: 12px;
          padding-left: 15px;
        }
      }
    }
  }
}

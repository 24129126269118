@import '../../styles/colors.scss';

.base {
  position: relative;
  background-color: red;
  padding: 0.1rem 0.2rem;
  font-size: 0.5rem;
  top: -10px;
  margin-left: 0.5rem;
  color: white;
}

@import '../../styles/colors.scss';

.base {
  display: flex;

  .tabLabelIcon {
    padding-right: 0rem;
  }

  &.horizontal {
    > [data-reach-tab-list] {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 20%;
      background: none;

      > [data-reach-tab] {
        background: none;
        border: 0;
        border-top: 0.0625rem solid $color-gray-6;
        color: $color-error-red;
        cursor: pointer;
        font-size: 1.075rem;
        outline: none;
        padding: 1rem;
        padding-right: 2.5rem;
        position: relative;
        text-align: left;

        &::before {
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          top: 0;
        }

        &::after {
          content: '\e939';
          font-family: 'micro-interaction-icons';
          font-size: 1rem;
          margin-top: -0.5rem;
          position: absolute;
          right: 1rem;
          top: 50%;
        }

        &:hover {
          background: $color-gray-6;
          color: $color-blue-1;

          &::before {
            width: 0.25rem;
            background: $color-blue-1;
          }
        }

        &[data-selected] {
          &::before {
            background: $color-error-red;
            width: 0.375rem;
          }
        }
      }
    }

    > [data-reach-tab-panels] {
      flex-grow: 1;

      > [data-reach-tab-panel] {
        border: none;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top: 0;
        outline: none;
        padding: 1rem;
      }
    }
  }

  &.vertical {
    flex-direction: column;

    > [data-reach-tab-list] {
      border-bottom: 0.0625rem solid $color-gray-5;
      display: flex;
      background: none;

      > [data-reach-tab] {
        background: none;
        border: none;
        bottom: -0.0625rem;
        color: $color-gray-1;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        outline: none;
        padding: 0.5rem 0.5rem;
        position: relative;

        &:not(:last-child) {
          border-right: 0;
        }

        &::after {
          bottom: 0;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          right: 0;
        }

        &:hover {
          color: $color-blue-1;

          &::after {
            height: 0.125rem;
            background: $color-blue-1;
          }
        }

        &[data-selected] {
          &::after {
            height: 0.25rem;
            background: $color-error-red;
          }
        }
      }
    }

    > [data-reach-tab-panels] {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      > [data-reach-tab-panel] {
        // border: 0.0625rem solid $color-gray-5;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top: 0;
        box-sizing: border-box;
        flex-grow: 1;
        outline: none;
        padding-top: 1rem;
      }
    }
  }
}

@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  // height: 130px;
  grid-template-areas:
    'mainDiv-panel';
  .mainDivPanel {
    grid-area: mainDiv-panel;
  }
  

  
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: 12fr;
    grid-template-areas: 'mainDiv-panel';
    font-size: 12px;
    padding-bottom: 10px;
    
    .mainDivPanel {
      display: flex;
      .link{
        padding-right: 1.4rem;                
        font-style: normal;
        font-weight: 600;
        font-size: 14.22px;
        line-height: 20px;
        color: #323334;
        cursor: pointer;
      }
     
    }
    hr{
      background-color:  #DADBDC;
     }
    
  }
}

@import '../../../styles/colors.scss';
.base {
  .header {
    display: block;
    //background: $color-cloud;

    .content {
      padding: 0 2.5rem .5rem;
    }
  }
  .body {
    padding: 0rem 2.5rem 4rem;
    min-height: 70vh;
  }
}
.h3Format{
  font-style: normal !important;
    font-weight: 700 !important;
    font-size: 22.78px !important;
    line-height: 15px !important;
    color: #424242 !important;
    margin-top: 4% !important;    
}
table {
  border: 0px solid;
  //background: #F4F4F4;
}
.tableColor {
  border: 0px solid;
  background: #F4F4F4;
  padding: 5px;
}
.thFormat{
  width: 10%;
    padding-top: .5%;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 14.22px;
    line-height: 16px;
    color: #424242;
}
.tdFormat{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #424242;  
}

.delvalFormat{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #424242;  
  padding-bottom: 1rem;
}

.modal {
  padding-top: 1rem;
  > div {
    display: flex;
    margin-bottom: 0.25rem;

    div:first-child {
      width: 150px;
      height: 50px;
      //font-weight: 500;
    }
  }
}

@import '../../../../styles/colors.scss';

.base {
  padding: 1rem 1rem;
  // border-bottom: 1px solid #efefef;
  border-bottom: 1px solid #424242;
  position: relative;
  overflow: hidden;
  button {
    font-weight: 500;
    color: #424242;
    text-decoration: none;
    &:hover {
      color: $color-blue-1;
    }
  }
}

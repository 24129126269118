@import '../../styles/colors.scss';

.base {
  box-sizing: border-box;
  height: 64px;
  position: relative;
  flex-shrink: 0;
  border-bottom: 3px solid #f7f7f7;

  .container {
    height: 100%;
    //Below added for header border line
    box-sizing: border-box;
    padding: 0rem 2.5rem;
    /* width: 1440px; */
    height: 74px;
    left: 0px;
    /* top: 41px; */
    background: #ffffff;
    box-shadow: 0px 2px 2px #00000023;

    > div {
      align-items: flex-end;
      box-sizing: border-box;
      display: flex;
      height: 100%;
      padding: 1rem 0 1.5rem;
    }

    .logo {
      display: inline-flex;

      svg {
        height: 1em;
        position: relative;
        top: -3px;
      }

      &.pointer {
        cursor: pointer;
      }
    }
  }

  .items {
    // display: flex;
    // flex-grow: 1;
    // justify-content: flex-start;
    margin-bottom: -0.7rem;
    margin-left: 2%;
  }
}

.uhc {
  &::after {
    background: $color-gradient-uhc;
  }
}

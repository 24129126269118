@import '../../styles/colors.scss';

.base {
  background: $color-white;
  box-shadow: 0 0.5rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.2);
  //font-size: 0.875rem;
  font-size: .77rem;
  // left: -50px;
  top: 63px;
  list-style: none;
  min-width: 100%;
  opacity: 0;
  padding: 0.25rem 0;
  position: absolute;
  transform: translateY(-1rem);
  transition: all 0.1s ease-in-out;
  transition-property: opacity, transform;
  visibility: hidden;
  width: max-content;
  z-index: 999;
}
.baseone {
  background: $color-white;
  box-shadow: 0 0.5rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.2);
  font-size: 0.875rem;
  right: 0;
  top: 27px;
  list-style: none;
  min-width: 100%;
  opacity: 0;
  padding: 0.25rem 0;
  position: absolute;
  transform: translateY(-1rem);
  transition: all 0.1s ease-in-out;
  transition-property: opacity, transform;
  visibility: hidden;
  width: max-content;
  z-index: 999;
}

.open {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  // margin-top: 27%;
}

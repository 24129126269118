@import '../../styles/colors.scss';

.content {
  width: 100%;
  padding-left: 10px;
  padding-top: 2px;
  font-size: 12px;
  padding-right: 10px;

  > table {
    border-collapse: collapse;
    width: 96%;
    text-align: left;
    > tbody > tr {
      border-bottom: 1.5px solid #000;
    }
    > tbody > tr > td {
      height: 30px;
      vertical-align: middle;
    }
    .formulaSpanShow {
      display: revert;
      height: auto;
      background-color: '#f2f2f';
      padding: 10px 10px;
    }
    .formulaSpanHide {
      display: none;
      height: auto;
      background-color: '#f2f2f';
      padding: 10px 10px;
    }
    .para {
      margin-bottom: 0px;
      line-height: 18px;
      padding-top: 10px;
    }
    .paraNote {
      font-style: 'italic';
      margin-bottom: '0px';
    }
  }
}

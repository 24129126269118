// Link to content fields
// https://github.optum.com/HealthcareMatching/hcm-admin-react-ui/tree/develop/src/components/Icon
// https://hubconnect.uhg.com/docs/DOC-90099

.base {
  font-family: 'micro-interaction-icons';
  font-style: normal;
}

.add {
  &:after {
    content: '\e96a';
  }
}

.alert_filled {
  &:after {
    content: '\e98c';
  }
}

.arrow_down {
  &:after {
    content: '\e937';
  }
}

.arrow_up {
  &:after {
    content: '\e93a';
  }
}

.arrow_right {
  &:after {
    content: '\e939';
  }
}

.export {
  &:after {
    content: '\e957';
  }
}

.home {
  &:after {
    content: '\e904';
  }
}

.import {
  &:after {
    content: '\e958';
  }
}

.info_filled {
  &:after {
    content: '\e99a';
  }
}

.remove {
  &:after {
    content: '\e968';
  }
}

.search {
  &:after {
    content: '\e96e';
  }
}

.warning_filled {
  &:after {
    content: '\e99b';
  }
}

.edit {
  &:after {
    content: '\e90c';
  }
}

.list {
  &:after {
    content: '\e95b';
  }
}

.view {
  &:after {
    content: '\e96c';
  }
}

.redo {
  &:after {
    content: '\e936';
  }
}

.deploy {
  &:after {
    content: '\e9f6';
  }
}

.thumbs_up {
  &::after {
    content: '\ea07';
  }
}

.download {
  &::after {
    content: '\e955';
  }
}

.play {
  &::after {
    content: '\e9bf';
  }
}

.pause {
  &::after {
    content: '\e9be';
  }
}

.stop {
  &::after {
    content: '\e9c0';
  }
}

.rewind {
  &::after {
    content: '\e940';
  }
}

.forward {
  &::after {
    content: '\e941';
  }
}

.settings {
  &::after {
    content: '\e90a';
  }
}

.immunization {
  &::after {
    content: '\e979';
  }
}

.allergies {
  &::after {
    content: '\e976';
  }
}

.chart_line {
  &::after {
    content: '\e948';
  }
}
.chart_line_isolated {
  &::after {
    content: '\e948';
  }
}

.calendar {
  &::after {
    content: '\e90d';
  }
}

.location {
  &::after {
    content: '\e91d';
  }
}

.asterisk {
  &::after {
    content: '\e930';
  }
}

.caret_up_centered {
  &::after {
    content: '\e992';
  }
}

.caret_down_centered {
  &::after {
    content: '\e993';
  }
}

.caret_right {
  &::after {
    content: '\e93f';
  }
}

.caret_left {
  &::after {
    content: '\e93e';
  }
}

.chevron_right {
  &::after {
    content: '\e943';
  }
}

.chevron_left {
  &::after {
    content: '\e942';
  }
}

.forward {
  &::after {
    content: '\e941';
  }
}

.rewind {
  &::after {
    content: '\e940';
  }
}

.sms_text {
  &::after {
    content: '\e9e0';
  }
}

.email {
  &::after {
    content: '\e914';
  }
}

.arrow_wtail_bold_up {
  &::after {
    content: '\e99d';
  }
}

.arrow_wtail_bold_down {
  &::after {
    content: '\e99e';
  }
}

.arrow_wtail_bold_right {
  &::after {
    content: '\e9a0';
  }
}

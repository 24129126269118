@import '../../styles/colors.scss';

.base {
  color: #424242; //$color-blue-1;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .dateWeek {
    width: 100%;

    .position {
      padding: 0;
      margin: 0;
      opacity: 0.6;
      text-transform: uppercase;
    }

    .positionButton {
      background-color: #fff;
      padding: 2px 6px;
      border-radius: 4px;
      cursor: pointer;
      opacity: 1;
      border: 0px;
      text-transform: uppercase;
    }
    .displayDateMargin {
      // margin-left: 360px;
      text-align: center;
      font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #002677;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}

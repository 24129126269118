[data-reach-dialog-overlay] {
  z-index: 9999;
}
.base {
  &[data-reach-dialog-content] {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: #424242;
    background-color: transparent;
    margin: 0;
    margin-left: 28%;
overflow: scroll;
  }
  .card {
    border: 0;
    box-shadow: 0 0.5rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.2);
  }
}

@import '../../styles/colors.scss';

.base {
  background: transparent;
  border: 0.0625rem solid $color-gray-1;
  box-sizing: border-box;
  color: $color-gray-1;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  min-width: 120px;
  outline: none;
  padding: 1rem 2rem;
  position: relative;
  transition: all 100ms ease-in-out;
  align-items: center;
  display: inline-flex;

  &:hover {
    .iconContainer svg {
      transform: translateX(6px);
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.1875rem $color-blue-3;
    // transform: translate(0);
  }

  &:active {
    box-shadow: none;
    // transform: translate(0);
  }
  &:disabled {
    background-color: $color-gray-4;
    border: 0.0625rem solid $color-gray-2;
    color: $color-gray-2;
    &:hover {
      box-shadow: none;
      // transform: translate(0);
      background-color: $color-gray-4;
      cursor: not-allowed;
    }
  }
}

.iconContainer {
  display: inline-block;
  height: 11px;
  overflow: hidden;
  position: relative;
  width: 24px;
  margin-left: 5px;

  svg {
    left: -6px;
    position: absolute;
    transition: transform 0.33s ease-in-out;
    g {
      fill: #000;
    }
  }
}

.primary {
  // background-image: linear-gradient(to right, #e40046, #b60c4f 52%, #e40046);
  background-color: transparent;
  border-color: transparent;
  color: $color-white;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:hover {
    &::before {
      transform: translateX(-50%);
    }
  }

  &::before {
    //background-image: linear-gradient(to right, #e40046, #b60c4f 52%, #e40046);
    background-color: #E40046;
    display: block;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.33s ease-in-out;
    width: 200%;
    z-index: -1;
  }

  &::hover {
    background: $color-blue-1;
  }

  svg {
    g {
      fill: #fff;
    }
  }
}

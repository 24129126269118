[data-reach-dialog-overlay] {
  z-index: 9999;
}
.base {
  &[data-reach-dialog-content] {
    position: fixed;
    
    // display: flex;
    align-items: center;
    justify-content: center;
    //background: #424242;
    background-color: transparent;  //Added
    margin: 0;
    
  }
  .card {
    border: 0;
    box-shadow: 0 0.5rem 0.5rem 0.0625rem rgba(0, 0, 0, 0.2);
  }
}

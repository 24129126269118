@import '../../styles/colors.scss';

.base {
  display: grid;
  gap: 20px;
  grid-template-areas:
    'title-panel'
    'count-panel'
    'filter-panel';
  .titlePanel {
    grid-area: title-panel;
  }
  .countPanel {
    grid-area: count-panel;
  }
  .filterPanel {
    grid-area: filter-panel;
  }
}

@media (min-width: 1000px) {
  .base {
    grid-template-columns: .5fr .6fr 2fr;
    grid-template-areas: 'title-panel count-panel filter-panel';
    font-size: 14px;
    margin-top:15px;
    .titlePanel {
      h4 {
        span {
          font-size: 10px;
          font-weight: normal;
        }
      }
    }
    .countPanel {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem;
      background-color: $color-cloud;
      .dateWeek {
        padding-left: 1rem;
        .position {
          font-size: 14px;
          padding: 0;
          margin: 0;
        }
        .headerSpan {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .filterPanel {
      // position: relative;
      // display: inline-block;
      // width: 99%;
      // height: 97%;
      // border: 1px solid;
      // float: right;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      width: 219px;
      height: 45px;
      background: #FFFFFF;
      // box-shadow: 0px 0px 4px rgb(0 0 0 / 50%);
      box-shadow: 0px 0px 4px #09090987
     
      }
      .arrow{
        height: 15px;
      width: 20px;
      float: right;
      display: block;
      margin-right: 3px;
      }
      .filterdivpopup{
        position: absolute;
        background-color: white;
        padding: 7px 15px;
        z-index: 1;
        width: 14%;
        border: 1px solid;
        margin-top: 16px;
        margin-left: -16px;
        box-shadow: 0px 0px 4px #09090987;
      }
  }
}

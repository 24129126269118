.base {
  background: transparent;
  border: 0;
  box-sizing: border-box;
  display: inline-flex;
  flex-grow: 1;
  font-family: inherit;
  font-size: 1rem;
  outline: none;
  padding: 0.5rem;
   padding-left:16px;
}
